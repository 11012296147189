import React, { ChangeEvent } from 'react'
import { ucfirst } from '../../helpers/utiles'

interface Option {
  key: string
  value: string
}

interface SelectFieldProps {
  label: string
  name: string
  options: Option[]
  value: string
  onChange: (value: any) => void
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  name,
  options,
  value,
  onChange,
}) => {
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // const selectedValue = event.target.value;
    onChange(event)
  }

  return (
    <div>
      <label>{ucfirst(label)}</label>
      <select
        className="form-control"
        name={name}
        value={value}
        onChange={handleSelectChange}
      >
        {options.map((option) => (
          <option key={option.key} value={option.value}>
            {option.key}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectField
