import React from 'react'
import { IUser } from '../models/IUser'
import { createData, setDataStatus } from '../api/request-service'
import AvatarCard from '../components/AvatarCard/AvatarCard'
import ButtonWithModal from '../components/ButtonWithModal/ButtonWithModal'
import ViewParticipants from '../components/ViewParticipants/ViewParticipants'
import ViewEmployees from '../components/ViewEmployees/ViewEmployees'
import ReadMore from '../components/ReadMore/ReadMore'
import ViewUserEvent from '../components/ViewUserEvent/ViewUserEvent'
import SwitchInput from '../components/SwitchInput/SwitchInput'
import PreviewImageOrVideo from '../components/PreviewImageOrVideo/PreviewImageOrVideo'
import ImgComponent from '../components/ImgComponent/ImgComponent'
import ViewEventTable from '../components/ViewEventTable/ViewEventTable'
import { siteConfig } from '../config/site-config'
import EventView from '../components/EventView/EventView'
import EventCategories from '../components/EventCategories/EventCategories'
import { toast } from 'react-toastify'


const getUserName = (user: IUser) => {
  return user.profile?.givenName + ' ' + user.profile?.familyName
}

const handleChangeRole = (e: any, user: IUser) => {
  setDataStatus('users', user.id, { role: e.target.value })
  toast.info(`Role updated to ${e.target.value} for user ${getUserName(user)}`)
}

const handleResendEmail = (e: any, invitation: any) => {
  console.log('resend email', invitation)
  createData(`invitations/${invitation.id}/resend-email`, {})
  toast.info(`Email resent to ${invitation.email}`)
}

export const renderHTMLContent = (data: any, name: string, model: string) => {
  if (name === 'fullName' && data?.profile) {
    return <AvatarCard user={data} withProfile={true} />
  }

  //   si c'est une description, affiche le contenu HTML
  if (name === 'description') {
    return <ReadMore text={data[name]} maxLength={200} />
  }

  if (model == 'events' && name == 'tables') {
    const length = data[name]?.length || 0
    let badgeClass = 'bg-primary'

    if (length > 20) {
      badgeClass = 'bg-danger'
    } else if (length > 10) {
      badgeClass = 'bg-warning'
    }
    return (
      <ButtonWithModal
        buttonText={
          <span className={`badge ${badgeClass}`}>
            <i className="fa fa-eye mx-2"></i>
            {length} tables
          </span>
        }
        Component={() => <ViewEventTable entityId={data.id} model={model} />}
      />
    )
  }

  if (model == 'users' && name == 'events') {
    const length = 2
    let badgeClass = 'bg-primary'

    if (length > 20) {
      badgeClass = 'bg-danger'
    } else if (length > 10) {
      badgeClass = 'bg-warning'
    }
    return (
      <ButtonWithModal
        buttonText={
          <span className={`badge ${badgeClass}`}>
            <i className="fa fa-eye mx-2"></i>
            {data.participantCount} events
          </span>
        }
        Component={() => <ViewUserEvent userId={data.id} />}
      />
    )
  }

  if(name === "imageUrl"){
    return <ImgComponent src={data[name
    ]} alt={data.name} style={{ width: '100px', height: '50px' }} />
  }

  if(name === 'resend' && model === 'invitations') {
    return (
      <button className='btn btn-success' onClick={(e)=>handleResendEmail(e,data)}>
        <i className="fa fa-paper-plane"></i>
      </button>
    )
  }

  if (name === 'status' && model === 'invitations') {
    return (
      <>
      {
        data?.type === 'INVITED' ? 
        <span
          className={`badge ${
            data[name] === 'ACCEPTED'
              ? 'bg-success'
              : data[name] === 'PENDING'
              ? 'bg-warning'
              : 'bg-danger'
          }`}
        >
          {data[name]}
        </span>
        :
        data[name] === 'ACCEPTED' ?
        <span
          className={`badge bg-success`}
        >
          {data[name]}
        </span>
        :
        <select
          className="form-control"
          defaultValue={data[name]}
          onChange={(e) =>
            setDataStatus(model, data.id, { status: e.target.value })
          }
          disabled={data[name] === 'ACCEPTED'}
        >
          <option value="ACCEPTED">Accepted</option>
          <option value="DECLINED">Declined</option>
          <option value="PENDING">Pending</option>
        </select>
      }
      </>
    )
  }
  if (name === 'status' && model === 'events') {
    //   export enum IEventStatus {
    //     Draft = "draft",          // IEvents are in draft mode, not visible to the public
    //     Cancelled = "cancelled",  // IEvent is cancelled
    //     Scheduled = "scheduled",  // IEvent is public and scheduled
    //     Ongoing = "ongoing"       // IEvent is in progress
    //   }

    // add select

    return (
      <select
        className="form-control"
        defaultValue={data[name]}
        onChange={(e) =>
          setDataStatus(model, data.id, { status: e.target.value })
        }
      >
        <option value="draft">Draft</option>
        <option value="cancelled">Cancelled</option>
        <option value="scheduled">Scheduled</option>
        <option value="ongoing">Ongoing</option>
      </select>
    )

    // return (
    //   <span
    //     className={`badge ${
    //       data[name] === 'scheduled' ? 'bg-success' : 'bg-danger'
    //     }`}
    //   >
    //     {data[name]}
    //   </span>
    // )
  }

  if (name === 'content') {
    return data[name]
  }
  if (name === 'isVisible') {
    return (
      <span className={`badge ${data[name] ? 'bg-success' : 'bg-danger'}`}>
        {data[name] ? 'Visible' : 'Invisible'}
      </span>
    )
  }

  const renderParticipantsOrEmployees = (type: string) => {
    const length = data[type]?.length || 0
    let badgeClass = 'bg-primary'

    if (length > 20) {
      badgeClass = 'bg-danger'
    } else if (length > 10) {
      badgeClass = 'bg-warning'
    }

    return (
      <>
        {type === 'participants' ? (
          <>
            <ButtonWithModal
              buttonText={
                <span className={`badge ${badgeClass}`}>
                  <i className="fa fa-eye mx-2"></i>
                  {length}/{data.maximumParticipantCapacity} participants
                </span>
              }
              Component={() => (
                <ViewParticipants
                  entityId={data.id}
                  capacity={data.maximumParticipantCapacity}
                  model={model}
                />
              )}
            />
          </>
        ) : (
          <ButtonWithModal
            buttonText={
              <>
              <span className={`badge ${badgeClass}`}>
                <i className="fa fa-eye mx-2"></i>
                {length} employees
              </span>

              {data.invitations?.length ?(
                <span className={`badge bg-primary`}>
                  <i className="fa fa-eye mx-2"></i>
                  {data.invitations.length} invitations
                </span>
              ) :null}
              </>
            }
            Component={() => <ViewEmployees 
              entityId={data.id} 
              model={model} company={data}
              invitations={data.invitations} 
              
              />}
          />
        )}
      </>
    )
  }

  if (name === 'role') {
    return (
      <div>
        {siteConfig.tenant === 'admin' ? (
          <select
            className="form-control"
            defaultValue={data[name]}
            onChange={(e) => handleChangeRole(e, data)}
          >
            <option value="default">Default</option>
            <option value="manager">Manager</option>
            <option value="admin">Admin</option>
          </select>
        ) : (
          // different color for each role
          <span
            className={`badge ${
              data[name] === 'admin'
                ? 'bg-danger'
                : data[name] === 'manager'
                ? 'bg-warning'
                : 'bg-primary'
            }`}
          >
            {data[name]}
          </span>
        )}
      </div>
    )
  }

  if (name === 'isActive') {
    //     <div class="form-check form-switch">
    //   <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
    //   <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>
    // </div>
    return (
      <SwitchInput
        status={data[name]}
        handleChange={(status: boolean) =>
          setDataStatus(model, data.id, { isActive: status })
        }
      />
    )
  }

  if (name === 'createdAt') {
    return (
      new Date(data[name]).toLocaleDateString() +
      ' ' +
      new Date(data[name]).toLocaleTimeString()
    )
  }

  if (name === 'participants' && data[name]) {
    return renderParticipantsOrEmployees('participants')
  }

  if (name === 'employees' && data[name]) {
    return renderParticipantsOrEmployees('employees')
  }

  if (name === 'event' && model === 'invitations') {
    return (
      <ButtonWithModal
        buttonText={
          <span className={`badge bg-primary`}>
            <i className="fa fa-eye mx-2"></i>
            {data?.event?.name}
          </span>
        }
        Component={() => <EventView event={data.event} />}
      />
    )
  }

  if (name === 'name' && model === 'events') {
    let imageUrl = ''
    if (data?.images?.length) {
      for (let i = 0; i < data.images.length; i++) {
        // verify is image
        if (data.images[i].match(/\.(jpeg|jpg|gif|png)$/) != null) {
          imageUrl = data.images[i]
          break
        }
      }
    }
    if (!imageUrl) {
      imageUrl = 'https://via.placeholder.com/150'
    }

    return (
      <div className="d-flex gap-2">
        <div className="event-image">
          <ButtonWithModal
            buttonText={
              <ImgComponent
                src={imageUrl}
                alt={data.name}
                style={{ width: '100px', height: '50px' }}
              />
            }
            Component={() => <PreviewImageOrVideo files={data.images} />}
          />
        </div>
        <div className="event-name">{data.name}</div>
      </div>
    )
  }

  if (name.toLocaleLowerCase() === 'categories' && model === 'events') {
    return (
      <ButtonWithModal
        buttonText={
          <span className={`badge bg-primary`}>
            <i className="fa fa-eye mx-2"></i>
            {data?.participantCategories?.length} categories
          </span>
        }
        Component={() => <EventCategories event={data as any} />}
        size='xl'
      />
    )
  }

  return data[name]?.toString() || ''
}
