import { ICompany } from './ICompany'
import { IEvent } from './IEvent'
import { IUser } from './IUser'

export interface IInvitation {
  id: string
  userId?: number
  eventId: number
  invitedById: number
  email?: string
  token?: string
  status: IInvitationStatus
  metadata?: any

  // Relations
  user?: IUser
  event: IEvent
  invitedBy: IUser
  company?: ICompany
  companyId?: string
  type: IInvitationType

  createdAt: Date
  updatedAt: Date
}

export enum IInvitationStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export enum IInvitationType {
  INVITED = 'INVITED',
  REQUESTED = 'REQUESTED'
}
