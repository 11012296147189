import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Modal } from 'react-bootstrap'
import './DropFileModal.css' // Ajoutez vos styles personnalisés ici
import { toast, ToastContainer, ToastPosition } from 'react-toastify'
import SelectEventField from '../SelectEventField/SelectEventField'

interface DropFileModalProps {
  show: boolean
  model: string
  onClose: () => void
  onUpload: (file: File, data: any) => void
}

const DropFileModal: React.FC<DropFileModalProps> = ({
  show,
  model,
  onClose,
  onUpload,
}) => {
  const [error, setError] = useState<string | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [selectedEventId, setSelectedEventId] = useState<string>('')

  const notify = (message: string) => {
    toast.success(message, {
      position: 'top-right' as ToastPosition, // Type cast
      autoClose: 5000,
    })
  }

  const handleFileUpload = (file: File) => {
    if (file.type !== 'text/csv') {
      setError(
        'Format de fichier invalide. Veuillez télécharger un fichier CSV.'
      )
      return
    }
    setError(null)
    if(model === 'invitations' && !selectedEventId) {
      setError('Veuillez sélectionner un événement.')
      return
    }

    if(model === 'invitations') {
      onUpload(file, {eventId: selectedEventId})
      return 
    }
    onUpload(file, null)
    notify('Fichier téléchargé avec succès !')
    onClose() // Ferme le modal après l'upload
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    if (files.length > 0) {
      handleFileUpload(files[0])
    }
    setIsDragging(false) // Réinitialiser l'état de drag
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(false)
  }
  /**
   * An object containing links to various model files.
   *
   * @type {Object}
   * @property {string} users - URL to the users model file.
   * @property {string} companies - URL to the companies model file.
   * @property {string} events - URL to the events model file.
   *
   * // Add other models and their links here
   */
  const modelFileLinks: any = {
    users: window.location.origin + '/datas/models/users.csv',
    companies: window.location.origin + '/datas/models/companies.csv',
    events: window.location.origin + '/datas/models/events.csv',
    invitations: window.location.origin + '/datas/models/invitations.csv',
  }

  const getModelFileLink = () => {
    return modelFileLinks[model] || '#' // Renvoie le lien du modèle ou '#' si non trouvé
  }

  const handleSelectEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value)
    setSelectedEventId(event.target.value)
  }



  return (
    <Modal show={show} size="xl" onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload {model} CSV File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Download a CSV file to import data for {model}.{' '}
          <a href={getModelFileLink()} rel="noopener noreferrer">
            Download an example of {model}
          </a>
        </p>

        {model === 'invitations' && (
          <div className="form-group py-5">
            <SelectEventField
              name="event-select"
              label="Select Event"
              value={selectedEventId}
              onChange={handleSelectEvent}

            />
          </div>
        )}

        <div
          className={`drop-area border border-dashed p-4 text-center ${
            isDragging ? 'dragging' : ''
          }`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onClick={() => document.getElementById('file-input')?.click()}
        >
          <p>
            Faites glisser et déposez votre fichier CSV ici, ou cliquez pour en
            sélectionner un.
          </p>
          <input
            type="file"
            id="file-input"
            accept=".csv"
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files) {
                handleFileUpload(e.target.files[0])
              }
            }}
          />
        </div>
        {error && <div className="alert alert-danger mt-2">{error}</div>}
        <div className="mt-3">
          <small>
            Accepted format : <strong>CSV (.csv)</strong>
          </small>
        </div>
        <ToastContainer />
      </Modal.Body>
    </Modal>
  )
}

export default DropFileModal
