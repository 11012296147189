import React from 'react';
import './ModelFilter.css';
import InvitationFilter from '../InvitationFilter/InvitationFilter';

interface ModelFilterProps {
    model: string
}

const ModelFilter: React.FC<ModelFilterProps> = ({model}) => {
    return (
        <div className="ModelFilter">
            {
                model === "invitations" && (
                    <InvitationFilter />
                )
            }
        </div>
    );
}

export default ModelFilter;
