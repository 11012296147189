import React, { useState, useEffect } from 'react'
import { IUser } from '../../models/IUser' // Import the IUser interface
import { getData } from '../../api/request-service'
import { IEventParticipant } from '../../models/IEvent'

interface ViewUserEventProps {
  userId: string
}

const ViewUserEvent: React.FC<ViewUserEventProps> = ({ userId }) => {
  const [user, setUser] = useState<IUser | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [participants, setParticipants] = useState<IEventParticipant[] | null>(
    null
  )
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchUserEvents = async () => {
      try {
        const result: any = await getData(`users/${userId}/events`)

        if (result.isSuccess) {
          setUser(result.user)
          setParticipants(result.participants)
        } else {
          setError(result.message)
        }
      } catch (err) {
        setError('Failed to fetch user events.')
      } finally {
        setLoading(false)
      }
    }

    fetchUserEvents()
  }, [userId])

  if (loading) return <p>Loading events...</p>
  if (error) return <p>{error}</p>

  return (
    <div>
      <h3>User Events</h3>
      <div>
        <div className="givenName">
          <strong>First Name </strong>: {user?.profile?.givenName}
        </div>
        <div className="familyName">
          <strong> Last Name </strong>: {user?.profile?.familyName}
        </div>
      </div>
      {participants?.length ? (
        <table className="table-auto border-collapse border border-gray-400 w-full w-100">
          <thead>
            <tr className="bg-gray-200">
              <th className="border px-4 py-2">#</th>
              <th className="border px-4 py-2">Event Name</th>
              <th className="border px-4 py-2">Joined At</th>
              <th className="border px-4 py-2">Role</th>
              <th className="border px-4 py-2">Starts At</th>
              <th className="border px-4 py-2">Ends At</th>
            </tr>
          </thead>
          <tbody>
            {participants.map((participant: IEventParticipant) => (
              <tr key={participant.event.id} className="hover:bg-gray-100">
                <td className="border px-4 py-2">
                  {/* position  inversé */}
                  {participants.length - participants.indexOf(participant)}
                </td>
                <td className="border px-4 py-2">{participant.event.name}</td>
                <td className="border px-4 py-2">
                  {new Date(participant.joinedAt).toLocaleDateString() +
                    ' ' +
                    new Date(participant.joinedAt).toLocaleTimeString()}
                </td>
                {/* <td className="border px-4 py-2">
                  <ReadMore
                    text={participant.event.description || ''}
                    maxLength={50}
                  />
                </td> */}
                <td className="border px-4 py-2">
                  <span
                    className={`badge ${
                      participant.role === 'organizer'
                        ? 'bg-primary'
                        : 'bg-secondary'
                    }`}
                  >
                    {participant.role}
                  </span>
                </td>
                <td className="border px-4 py-2">
                  {new Date(participant.event.startsAt!).toLocaleDateString()}
                </td>
                <td className="border px-4 py-2">
                  {new Date(participant.event.endsAt!).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No events available for this user.</p>
      )}
    </div>
  )
}

export default ViewUserEvent
