import React, { useState } from 'react'

interface SearchFormProps {
  onSearch: (query: string) => void // Fonction appelée lors de la recherche
  placeholder?: string // Placeholder optionnel pour le champ de recherche
}

const SearchForm: React.FC<SearchFormProps> = ({
  onSearch,
  placeholder = 'Rechercher...',
}) => {
  const [query, setQuery] = useState<string>('') // État local pour la requête de recherche

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value) // Met à jour l'état local avec la nouvelle valeur
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Empêche le rechargement de la page
    onSearch(query) // Appelle la fonction de recherche avec la requête actuelle
  }

  return (
    <>
      <div className="mb-2">
        <strong>
          {/* Rechercher par nom */}
          Search form
        </strong>
      </div>
      <form onSubmit={handleSubmit} className="d-flex py-2">
        <input
          type="search"
          value={query}
          onChange={handleInputChange}
          placeholder={placeholder}
          className="form-control me-2" // Ajoute un peu d'espace à droite
        />
        <button type="submit" className="btn btn-primary">
          <i className="fa fa-search"></i>
          {/* Rechercher */}
        </button>
      </form>
    </>
  )
}

export default SearchForm
