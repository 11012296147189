import React, { FC, useEffect, useState } from 'react'
import './AdminPanel.css'
import SideBar from '../SideBar/SideBar'
import { adminMenuItems } from '../../datas/menuItems'
import ModelTable from '../ModelTable/ModelTable'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getItem } from '../../helpers/localsorage.service'
import Loading from '../Loading/Loading'
import { ucfirst } from '../../helpers/utiles'
import { siteConfig } from '../../config/site-config'
import { getMainColor } from '../../lib/utiles'
interface AdminPanelProps {}

const AdminPanel: FC<AdminPanelProps> = () => {
  const navigate = useNavigate()
  const { model } = useParams<{ model: string }>()
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  let page: any = queryParams.get('page')
  let limit: any = queryParams.get('limit')
  const mainColor = getMainColor()

  const storedPage = getItem('adminPage')
  const storedLimit = getItem('adminLimit')

  page = page || storedPage || 1
  limit = limit || storedLimit || 5

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      setLoading(false)
    }
    runLocalData()
  }, [model, page, limit])
  const models = ['users', 'companies', 'events', 'invitations']

  if (model && !models.includes(model)) {
    navigate(`/${siteConfig.tenant}/users?page=1`)
  }

  return (
    <div className="admin-panel">
      <SideBar menuItems={adminMenuItems} />
      <main className="main-content">
        <div className={`main-content-header ${mainColor}`}>
          <h1 className="m-0"> {ucfirst(siteConfig.tenant!)} Plateform</h1>
         
        </div>
        <div className="main-content-body">
          {loading ? (
            <Loading />
          ) : (
            <ModelTable key={model}  page={page} limit={limit} model={model!} />
          )}
        </div>
      </main>
    </div>
  )
}

export default AdminPanel
