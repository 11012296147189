import React, { FC, Fragment } from 'react'
import './EventView.css'
import { IEvent } from '../../models/IEvent'
import { format } from 'date-fns'

interface EventViewProps {
  event: IEvent
}

const EventView: FC<EventViewProps> = ({ event }) => {
  const startDate = format(
    new Date(event?.startsAt as Date),
    'dd/MM/yyyy HH:mm'
  )
  const endDate = format(new Date(event?.endsAt as Date), 'dd/MM/yyyy HH:mm')

  return (
    <Fragment>
      <div className="container event-view">
        <div className="row event-view__header">
          <div className="col">
            <h2>{event.name}</h2>
            <p>{event.description}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <table className="table table-bordered mt-4">
              <tbody>
                {/* Row for Location */}
                <tr>
                  <th scope="row">Location</th>
                  <td>
                    {event.location?.name}
                    <br />
                    {event.location?.formattedAddress}
                  </td>
                </tr>

                {/* Row for Capacity */}
                <tr>
                  <th scope="row">Capacity</th>
                  <td>{event.maximumParticipantCapacity}</td>
                </tr>

                {/* Additional Event Info (if needed) */}
                {/* Example: Longitude/Latitude */}
                <tr>
                  <th scope="row">Coordinates</th>
                  <td>
                    Latitude: {event.location?.latitude}
                    <br />
                    Longitude: {event.location?.longitude}
                  </td>
                </tr>

                {/* Tables (if they exist) */}
                {event.tables && event.tables.length > 0 && (
                  <tr>
                    <th scope="row">Tables</th>
                    <td>
                      {event.tables.map((table, index) => (
                        <span key={index} className="badge">
                          {table.name}
                          {index < event.tables.length - 1 && ' '}
                        </span>
                      ))}
                    </td>
                  </tr>
                )}

                <tr>
                  <th scope="row">Start At</th>
                  <td>{startDate}</td>
                </tr>
                <tr>
                  <th scope="row">End At</th>
                  <td>{endDate}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default EventView
