import React from 'react'
import { Pagination } from 'react-bootstrap'
import './CustomPagination.css'

interface CustomPaginationProps {
  pageInfo: {
    currentPage: number
    totalItems: number
    itemsPerPage: number
    totalPages: number
    hasNextPage: boolean
    hasPreviousPage: boolean
  }
  onPageChange: (page: number) => void
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  pageInfo,
  onPageChange,
}) => {
  const { currentPage, totalPages, totalItems } = pageInfo

  // Ne pas afficher la pagination si il n'y a qu'une seule page
  if (totalPages <= 1) {
    return null // ou <></> pour un fragment vide
  }

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page)
    }
  }

  const renderPaginationItems = () => {
    const pagesToShow = 2 // Nombre de pages à afficher avant et après la page actuelle
    const items: JSX.Element[] = []

    // Afficher le bouton "First"
    items.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      />
    )

    // Afficher les pages avant la page actuelle
    for (let i = Math.max(1, currentPage - pagesToShow); i < currentPage; i++) {
      items.push(
        <Pagination.Item key={i} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      )
    }

    // Afficher la page actuelle
    items.push(
      <Pagination.Item key={currentPage} active>
        {currentPage}
      </Pagination.Item>
    )

    // Afficher les pages après la page actuelle
    for (
      let i = currentPage + 1;
      i <= Math.min(totalPages, currentPage + pagesToShow);
      i++
    ) {
      items.push(
        <Pagination.Item key={i} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      )
    }

    // Afficher le bouton "Last"
    items.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      />
    )

    return items
  }

  return (
    <div className="data-pagignation">
      <div className="mb-2">
        <strong>
          {`Total: ${totalItems} items | Page ${currentPage} of ${totalPages}`}
        </strong>
      </div>
      <Pagination>{renderPaginationItems()}</Pagination>
    </div>
  )
}

export default CustomPagination
