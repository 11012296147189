import { FC, useEffect, useState } from 'react'
import { Title } from 'rizzui' // Utilisation de rizzui

import { createData, getData } from '../../api/request-service'
import { IEventMeetingTable } from '../../models/IEvent'
import TableLine from '../TableLine/TableLine'

interface ViewEventTableProps {
  entityId: string
  model: string
}

const ViewEventTable: FC<ViewEventTableProps> = ({ entityId, model }) => {
  const [tables, setTables] = useState<IEventMeetingTable[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  // const [totalParticipants, setTotalParticipants] = useState<number>(0)

  const fetchParticipants = async () => {
    try {
      setLoading(true)
      setError(null)
      const response = await getData(`${model}/${entityId}/tables`, {
        page,
        limit: pageSize,
      })

      setTables(response.data)
      // setTotalParticipants(response.total)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchParticipants()
  }, [entityId, page, pageSize])

  const handleAddTable = async () => {
    try {
      const newTable = {
        name: 'New Tablev #' + new Date().getTime(),
      }
      const result = await createData(`events/${entityId}/tables`, {
        action: 'add',
        table: newTable,
      })

      if (result?.table) {
        setTables([result.table, ...tables])
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleDeleteTable = async (table: IEventMeetingTable) => {
    try {
      await createData(`events/${entityId}/tables`, {
        action: 'remove',
        table: table,
      })

      setTables(tables.filter((t) => t.id !== table.id))
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleUpdateTable = async (
    table: IEventMeetingTable,
    value: string
  ) => {
    try {
      const result = await createData(`events/${entityId}/tables`, {
        action: 'update',
        table: { ...table, name: value },
      })

      if (result?.table) {
        setTables(tables.map((t) => (t.id === table.id ? result.table : t)))
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const renderTables = (tableDatas: IEventMeetingTable[]) => {
    return (
      <>
        {tableDatas?.length > 0 ? (
          <>
            <table className="table-auto table-striped border-collapse border border-gray-400 w-full w-100">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border px-4 py-2">N#</th>
                  <th className="border px-4 py-2">Name</th>
                  <th className="border px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tableDatas.map((table, index) => (
                  <tr key={table.id} className="hover:bg-gray-100">
                    <td className="border px-4 py-2">{index + 1}</td>

                    <td className="border px-4 py-2" width="80%">
                      <TableLine
                        defaultValue={table.name}
                        handleSave={(value) => handleUpdateTable(table, value)}
                      />
                    </td>

                    <td className="border px-4 py-2">
                      <button
                        onClick={() => handleDeleteTable(table)}
                        className="px-4 btn btn-danger py-2 bg-red-500 text-white mx-1 "
                      >
                        <i className="fa fa-trash mx-1"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <div className="text-center">No tables found.</div>
        )}
      </>
    )
  }

  if (loading) {
    return <div>Loading tables...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <div className="ViewEventTable p-10">
      <div className="mb-6 flex items-center justify-between">
        <Title as="h3" className="text-lg">
          Table List
        </Title>

        <button className="btn btn-primary my-2" onClick={handleAddTable}>
          <i className="fa fa-plus mx-1"></i>
          Add New Table
        </button>
      </div>
      {renderTables(tables)}
      <button onClick={() => setPage(2)}></button>
      <button onClick={() => setPageSize(22)}></button>
    </div>
  )
}

export default ViewEventTable
