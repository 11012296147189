import React, { FC, useEffect, useState } from 'react'
import { Title } from 'rizzui' // Utilisation de rizzui
import Papa from 'papaparse'
import { IParticipant, IParticipantRole } from '../../models/IParticipant'

import SearhUserOrCompany from '../SearhUserOrCompany/SearhUserOrCompany'
import { createData, getData } from '../../api/request-service'
import { IUser } from '../../models/IUser'
import './ViewParticipants.css'
import InvitationForm from '../InvitationForm/InvitationForm'
import NewPagination from '../DataPagination/NewPagination'
interface ViewParticipantsProps {
  entityId: string
  capacity?: number
  model: string
}

const ViewParticipants: FC<ViewParticipantsProps> = ({
  entityId,
  capacity = 0,
  model,
}) => {
  const [participants, setParticipants] = useState<IParticipant[]>([])
  const [filteredParticipants, setFilteredParticipants] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [pageSize, setPageSize] = useState<number>(7)
  const [totalParticipants, setTotalParticipants] = useState<number>(0)
  const [showInvitationForm, setShowInvitationForm] = useState<boolean>(false)
  const [currentePage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentTab, setCurrentTab] = useState<IParticipantRole | 'all'>('all')

  const [removingParticipantId, setRemovingParticipantId] = useState<
    string | null
  >(null)

  const fetchParticipants = async (role: any = 'all') => {
    try {
      setLoading(true)
      setError(null)
      const queryName = model === 'events' ? 'participants' : 'employees'
      const response = await getData(`${model}/${entityId}/${queryName}`, {
        page: currentePage,
        limit: pageSize,
        role: role || currentTab,
      })
      console.log(response.data)
      setParticipants(response.data)
      setTotalPages(response.totalPages)
      setTotalParticipants(response.total)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchParticipants()
  }, [entityId])

  const handleAddParticipant = async () => {
    console.log({ filteredParticipants })
    try {
      await createData(`events/${entityId}/participants`, {
        action: 'add',
        userIds: filteredParticipants,
      })
      fetchParticipants()
      setCurrentPage(1)
      setPageSize(10)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleRemoveParticipant = async (participant: IParticipant) => {
    try {
      setRemovingParticipantId(participant.id)
      await createData(`events/${entityId}/participants`, {
        action: 'remove',
        userIds: [participant.id],
      })
      fetchParticipants()
      setRemovingParticipantId(null)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleChangeRole = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    participant: IParticipant
  ) => {
    try {
      const participantId =
        participant.type === 'user' ? participant.userId : participant.companyId
      await createData(`events/${entityId}/participants`, {
        action: 'update',
        userIds: [participantId],
        role: e.target.value,
      })
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleSelectFile = async () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.csv'
    fileInput.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0]
      if (!file) return

      if (file.type !== 'text/csv') {
        setError('Invalid file format. Please upload a CSV file.')
        return
      }

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results: {
          data: any[]
          errors: any[]
          meta: any
        }) => {
          const formattedData: Partial<IUser>[] = results.data.map(
            (row: any) => ({
              email: row.email,
              phone: row.phone,
              givenName: row.firstName,
              familyName: row.lastName,
            })
          )

          try {
            await createData(`events/${entityId}/participants/csv`, {
              participants: formattedData,
            })
            fetchParticipants()
          } catch (err) {
            setError(
              err instanceof Error ? err.message : 'An unknown error occurred'
            )
          }
        },
        error: (parseError: Error) => {
          console.error('Error parsing CSV:', parseError)
          setError('Error parsing CSV file.')
        },
      })
    }
    fileInput.click()
  }

  const handleFilterByRole = async (e: any, role: IParticipantRole | 'all') => {
    e.preventDefault()
    setCurrentTab(role)
    fetchParticipants(role)
  }
  const renderParticipants = (partDatas: IParticipant[]) => {
    return (
      <>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className={`nav-link ${currentTab === 'all' ? 'active' : ''}`}
              id={`all-tab`}
              data-toggle="tab"
              href={`#all`}
              role="tab"
              aria-controls="all"
              aria-selected={currentTab === 'all'}
              onClick={(e) => handleFilterByRole(e, 'all')}
            >
              All
            </a>
          </li>
          {Object.values(IParticipantRole).map((role) => (
            <li className="nav-item" role="presentation" key={role}>
              <a
                className={`nav-link ${currentTab === role ? 'active' : ''}`}
                id={`${role}-tab`}
                data-toggle="tab"
                href={`#${role}`}
                role="tab"
                aria-controls={role}
                aria-selected={currentTab === role}
                onClick={(e) => handleFilterByRole(e, role)}
              >
                {role}
              </a>
            </li>
          ))}
        </ul>
        {partDatas?.length > 0 ? (
          <>
            <div className="tab-content" id="myTabContent">
              {Object.values(IParticipantRole).map((role, postion) => (
                <div
                  className={`tab-pane fade ${
                    postion === 0 ? 'show active' : ''
                  }`}
                  id={role}
                  role="tabpanel"
                  aria-labelledby={`${role}-tab`}
                  key={role}
                >
                  <table className="table-auto border-collapse border border-gray-400 w-full w-100">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border px-4 py-2">N#</th>
                        <th className="border px-4 py-2">Name</th>
                        <th className="border px-4 py-2">Role</th>
                        <th className="border px-4 py-2">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {partDatas.map((participant, index) => (
                        <tr key={participant.id} className="hover:bg-gray-100">
                          <td className="border px-4 py-2">
                            {totalParticipants -
                              (currentePage - 1) * pageSize -
                              index}
                          </td>
                          {participant?.user && (
                            <td className="border px-4 py-2">
                              {`${participant?.user?.profile?.givenName} ${participant?.user?.profile?.familyName}`}
                              <span className="badge badge-success bg-danger m-2">
                                user
                              </span>
                            </td>
                          )}
                          {participant?.company && (
                            <td className="border px-4 py-2">
                              {participant.company.name}
                              <span className="badge badge-info m-2">
                                company
                              </span>
                            </td>
                          )}
                          <td className="border px-4 py-2">
                            <select
                              className="form-control"
                              defaultValue={participant.role}
                              onChange={(e) => handleChangeRole(e, participant)}
                            >
                              {participant?.user &&
                                Object.values(IParticipantRole)
                                  .filter((item) => item != 'exponent')
                                  .map((name) => (
                                    <option key={name} value={name}>
                                      {name}
                                    </option>
                                  ))}
                              {participant?.company &&
                                Object.values(IParticipantRole).map((name) => (
                                  <option key={name} value={name}>
                                    {name}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td className="border px-4 py-2">
                            <button
                              onClick={() =>
                                handleRemoveParticipant(participant)
                              }
                              className="px-4 btn btn-danger py-2 bg-red-500 text-white "
                            >
                              <i className="fa fa-trash mx-1"></i>
                              {removingParticipantId === participant.id
                                ? 'Removing...'
                                : 'Remove'}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </>
        ) : (
            <div className="text-center">No participants found for the role "{currentTab}".</div>
        )}
      </>
    )
  }
  const handleSendInvitation = async (emails: string[]) => {
    const users = emails.map((email) => ({
      email,
      eventId: entityId,
      firstName: '',
      lastName: '',
    }))
    for (const user of users) {
      try {
        await createData(`invitations`, user)
      } catch (err) {
        setError(
          err instanceof Error ? err.message : 'An unknown error occurred'
        )
      }
    }
  }

  const onPageChange = (value: any) => {
    //setCurrentPage(value)
    fetchParticipants(value)
  }

  if (loading) {
    return <div>Loading participants...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <div className="ViewParticipants p-10">
      <div className="mb-6 flex items-center justify-between">
        <Title as="h3" className="text-lg">
          Participants List ({totalParticipants} / {capacity})
          {totalParticipants < capacity && (
            <div>{capacity - totalParticipants} remaining places</div>
          )}
        </Title>
      </div>

      {totalParticipants < capacity && (
        <div className="flex">
          <div className="">
            <SearhUserOrCompany
              onSelectionChange={setFilteredParticipants}
              handleAdd={handleAddParticipant}
            />
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end">
        {showInvitationForm ? (
          <InvitationForm
            onCancel={() => setShowInvitationForm(false)}
            onSend={handleSendInvitation}
          />
        ) : (
          <button
            className="px-4 py-2 btn btn-primary my-2 bg-green-500 text-white"
            onClick={() => setShowInvitationForm(true)}
          >
            <i className="fa fa-envelope mx-1"></i>
            Send Invitation
          </button>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="px-4 py-2 btn btn-success my-2 bg-blue-500 text-white"
          onClick={handleSelectFile}
        >
          <i className="fa fa-file mx-1"></i>
          Import CSV
        </button>
      </div>
      {renderParticipants(participants)}
      <NewPagination
        currentPage={currentePage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default ViewParticipants
