interface TenantConfig {
  className: string
  title: string
  description: string
  logo: string
  mainColor: string
}

export const tenantConfig: Record<string, TenantConfig> = {
  admin: {
    className: 'admin',
    title: 'Admin',
    description: 'Admin App.',
    logo: 'admin.png',
    mainColor: 'bg-primary',
  },
  manager: {
    className: 'manager',
    title: 'Manager',
    description: 'Manager App.',
    logo: 'manager.png',
    mainColor: 'bg-success',
  },
}
