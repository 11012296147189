import { siteConfig } from '../config/site-config'

export const adminMenuItems = [
  {
    label: 'Events',
    path: `/${siteConfig.tenant}/events?page=1`,
    icon: 'fa fa-home',
  },
  {
    label: 'Users',
    path: `/${siteConfig.tenant}/users?page=1`,
    icon: 'fa fa-users',
  },
  {
    label: 'Companies',
    path: `/${siteConfig.tenant}/companies?page=1`,
    icon: 'fa fa-table',
  },
  {
    label: 'Invitations',
    path: `/${siteConfig.tenant}/invitations?page=1`,
    icon: 'fa fa-envelope',
  },
]

export const managerMenuItems = [
  {
    label: 'Events',
    path: `/${siteConfig.tenant}/events?page=1`,
    icon: 'fa fa-home',
  },
  {
    label: 'Invitations',
    path: `/${siteConfig.tenant}/invitations?page=1`,
    icon: 'fa fa-envelope',
  },
  {
    label: 'Companies',
    path: `/${siteConfig.tenant}/companies?page=1`,
    icon: 'fa fa-table',
  },
]

export const mergedMenuItems = {
  admin: adminMenuItems,
  manager: managerMenuItems,
}
