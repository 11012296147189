import React, { FC, useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import './ViewModel.css'
import Loading from '../Loading/Loading'
import { getFields } from '../../helpers/manage-routes'
import { ucfirst } from '../../helpers/utiles'
import { getDataById } from '../../api/request-service'
import ReadMore from '../ReadMore/ReadMore'

interface ViewModelProps {
  model: string
  modelId: string
  handleClose: () => void
}

const ViewModel: FC<ViewModelProps> = ({ handleClose, model, modelId }) => {
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState(true)
  const [columnInputs, setColumnInputs] = useState<any>([])
  const [data, setData] = useState<any>()
  const [showMediaOnly] = useState(false) // Filtre pour médias uniquement

  // Charger les données du modèle et initialiser les colonnes visibles
  const runLocalData = async () => {
    setShowForm(true)
    setLoading(false)

    const newCol = getFields(model)
    setColumnInputs(newCol)

    if (modelId) {
      const newData = await getDataById(model, modelId)
      setData(newData)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    runLocalData()
  }, [model, modelId])

  // Vérifier si l'URL est une image
  const isImageUrl = (url: string) => /\.(jpeg|jpg|gif|png|webp)$/.test(url)

  // Vérifier si l'URL est une vidéo
  const isVideoUrl = (url: string) => /\.(mp4|webm|ogg)$/.test(url)

  // Vérifier si une colonne contient des médias (image/vidéo)
  const isMediaColumn = (columnName: string) => {
    const value = data[columnName]
    return typeof value === 'string' && (isImageUrl(value) || isVideoUrl(value))
  }

  // Rendu du contenu de chaque colonne
  const renderColumnContent = (column: any) => {
    if (!data) return <td></td>
    const value = data[column.name]
    

    if (column.name.includes('image') || column.name.includes('cover')) {
      if (Array.isArray(value)) {
        return (
          <td scope="row">
            <div className="row">
              {value.map((img: string, index: number) => (
                <div className="col-md-6" key={index}>
                  <img src={img} alt={column.label} width="100%" />
                </div>
              ))}
            </div>
          </td>
        )
      } else {
        return (
          <td scope="row">
            <img
              src={value}
              alt={column.label}
              style={{ maxWidth: '100px', maxHeight: '100px' }}
            />
          </td>
        )
      }
    } else if (column.name.includes('video')) {
      if (Array.isArray(value)) {
        return (
          <td scope="row">
            <div className="row">
              {value.map((vid: string, index: number) => (
                <div className="col-md-6" key={index}>
                  <video width="100%" controls>
                    <source src={vid} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ))}
            </div>
          </td>
        )
      } else {
        return (
          <td scope="row">
            <video width="100" controls>
              <source src={value} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </td>
        )
      }
    } else if (column.name === 'description') {
      return (
        <td scope="row">
          <ReadMore text={data[column.name]} maxLength={50} />
        </td>
      )
    } else if (column.name.endsWith('At')) {
      return <td scope="row">{new Date(data[column.name]).toLocaleString()}</td>
    } else if (column.name === 'givenName') {
      return <td scope="row">{data.profile.givenName}</td>
    } else if (column.name === 'familyName') {
      return <td scope="row">{data.profile.familyName}</td>
    } else if (column.name === 'placeName') {
      return <td scope="row">{data.location.name}</td>
    } else if (column.name === 'formattedAddress') {
      return <td scope="row">{data.location.formattedAddress}</td>
    } else if (column.name === 'longitude') {
      return <td scope="row">{data.location.longitude}</td>
    } else if (column.name === 'latitude') {
      return <td scope="row">{data.location.latitude}</td>
    } else if (column.name === 'size') {
      return (
        <td scope="row">
          {data.size.length === 1 ? data.size[0] + '+' : data.size.join('-')}
        </td>
      )
    } else if (column.name === 'lookingFor') {
      return (
        <td scope="row">
          {data.lookingFor.map((item: string, index: number) => (
            <span key={index} className="badge badge-primary">
              {' '}
              {item}{' '}
            </span>
          ))}
        </td>
      )
    } else if (column.name === 'tables') {
      return (
        <td scope="row">
          {data.tables.map((table: { id: string; name: string }) => (
            <span key={table.id} className="badge badge-primary">
              {' '}
              {table.name}{' '}
            </span>
          ))}
        </td>
      )
    }else if (column.name.toLowerCase() === 'event') {
      return (
        <td scope="row">
          
        </td>
      )
    } 
    
    else {
      return <td scope="row">{data[column.name]?.toString() || ''}</td>
    }
  }

  // Rendu du tableau avec toutes les colonnes visibles
  const renderTableHeader = () => {
    if (!data) return null

    return (
      <table className="table table-bordered table-striped">
        <tbody>
          {columnInputs
            ?.filter((column: any) =>
              showMediaOnly ? isMediaColumn(column.name) : true
            ) // Filtrer les colonnes si le filtre média est activé
            .map((column: any, index: number) => (
              <tr key={index} style={{ height: '60px' }}>
                <th scope="col">{ucfirst(column.label)}</th>
                {renderColumnContent(column)}
              </tr>
            ))}
        </tbody>
      </table>
    )
  }

  // Titre du modal basé sur le modèle
  const renderViewTitle = () => {
    let newModelName = ''
    if (model === 'users') {
      newModelName = 'User'
    } else if (model === 'events') {
      newModelName = 'Event'
    } else if (model === 'companies') {
      newModelName = 'Company'
    }

    return <h3>{newModelName} Details</h3>
  }

  return (
    <Modal size="lg" centered show={showForm} scrollable onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{renderViewTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading || !data ? <Loading /> : <>{renderTableHeader()}</>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ViewModel
