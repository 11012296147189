import React, { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form' // Form handling with react-hook-form
import { LoginSchema } from '../../validators/login.schema'
import { signin } from '../../api/request-service'
import './SignIn.css'
import { getCookie, getMainColor } from '../../lib/utiles'
import { siteConfig } from '../../config/site-config'
import { ucfirst } from '../../helpers/utiles'
import { toast } from 'react-toastify'

interface SignInProps {}

const initialValues: LoginSchema = {
  email: '',
  rememberMe: true,
}

const SignIn: FC<SignInProps> = () => {
  const tenant = siteConfig.tenant
  const [errorMessage, setErrorMessage] = useState<string>('')
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchema>({
    defaultValues: initialValues,
  })

  useEffect(() => {
    // Check if the user is already authenticated
    const accessToken =
      localStorage.getItem('accessToken') || getCookie('accessToken')

    if (accessToken) {
      navigate(`/${tenant}/events`) // Redirect to admin if authenticated
    }
  }, [navigate])

  // Form submission handler
  const onSubmit: SubmitHandler<LoginSchema> = async (data) => {
    try {
      const result = await signin(data) // Call the sign-in API

      if (result.statusCode === 200) {
        const token = result.accessVerifyCodeRouteToken // Extract token
        navigate(`/signin/verification-code-form?token=${token}`) // Redirect with token
        toast.success('Verification code sent to your email')
      } else {
        setErrorMessage(result.message)
        toast.error(result.message)
      }
    } catch (error: any) {
      toast.error('Error while signing in, please try again')
    }
  }

  const handleChangePlateform = (platform: string) => {
    const domain = window.location.hostname.split('.').slice(1).join('.')
    window.location.href = `${window.location.protocol}//${platform}.${domain}/signin`
  }

  const mainColor = getMainColor()

  return (
    <div
      className={`signin d-flex flex-column justify-content-center align-items-center min-vh-100 text-white' ${mainColor}`}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="shadow-lg p-3 bg-white text-dark"
      >
        <h2 className="text-center">Faacer {ucfirst(siteConfig.tenant!)}</h2>
        <div className="m-2 d-flex justify-content-center">
          <img src="/faacer.png" alt="logo faacer" className="rounded-3" />
        </div>
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
        <div className="mb-3">
          <label htmlFor="email" className="form-label text-center">
            Email address
          </label>
          <input
            type="email"
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            id="email"
            placeholder="Enter your email"
            {...register('email')}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email.message}</div>
          )}
        </div>

        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="rememberMe"
            {...register('rememberMe')}
          />
          <label className="form-check-label" htmlFor="rememberMe">
            Remember Me
          </label>
        </div>

        <button type="submit" className="btn btn-success w-100">
          Sign In
        </button>

        <div className="text-center m-3">
          {siteConfig.tenant === 'admin' ? (
            <a href="#" onClick={() => handleChangePlateform('manager')}>
              Go to Manager Plateform
            </a>
          ) : (
            <a href="#" onClick={() => handleChangePlateform('admin')}>
              Go to Admin Plateform
            </a>
          )}
        </div>
        <div>
          <div className="app-links text-center">
            <p>Download our mobile app:</p>
            <div className="icon-box d-flex justify-content-between gap-2" >
              <a
                href="https://apps.apple.com/be/app/faacer/id6478287726"
                target="_blank"
                className='m-2'
              >
                <img
                  src="/assets/images/logos/play-store.png"
                  alt="Download on iOS"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.beprosoft.faacer&hl=fr"
                target="_blank"
                className='m-2'
              >
                <img
                  src="/assets/images/logos/google-play.png"
                  alt="Get it on Android"
                />
              </a>

            </div>
          </div>
          {/* <div className="footer">
           
            <span>faacer.com</span>
            <p>© FAACER. All rights reserved.</p>
          </div> */}
        </div>
      </form>
    </div>
  )
}

export default SignIn
