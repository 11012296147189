import React, { useEffect } from 'react'
import TenantRouter from './components/TenantRouter/TenantRouter'
import { siteConfig } from './config/site-config'
import { ucfirst } from './helpers/utiles'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App: React.FC = () => {
  useEffect(() => {
    const tenant = siteConfig.tenant
    document.title = `Faacer - ${ucfirst(tenant!)} Plateform`

    // Désactiver les logs en production
    if (process.env.NODE_ENV === 'production') {
      console.log = () => {}
      console.warn = () => {}
      console.error = () => {}
      console.info = () => {}
      console.debug = () => {}
    }
    toast.info(`Bienvenue sur la plateforme ${ucfirst(tenant!)}`)
  }, [])

  return <>
  <TenantRouter />
  <ToastContainer />
</>
}

export default App
