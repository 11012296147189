import React, { FC, useEffect, useState } from 'react'
import { Title } from 'rizzui' // Utilisation de rizzui
import AvatarCard from '../AvatarCard/AvatarCard'
import { createData, getData, setDataStatus } from '../../api/request-service'
import { IParticipant } from '../../models/IParticipant'
import SearhUserInput from '../SearhUserInput/SearhUserInput'
import { ICompany } from '../../models/ICompany'
import { IInvitation } from '../../models/IInvitation'
import { toast } from 'react-toastify'

interface ViewEmployeesProps {
  entityId: string
  model: string
  company?: ICompany
  invitations?: IInvitation[]
}

const ViewEmployees: FC<ViewEmployeesProps> = ({ entityId, model, company, invitations }) => {
  const [employees, setEmployees] = useState<IParticipant[]>([])
  const [filteredEmployees, setFilteredEmployees] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(50) // Nombre de employees par page
  const [totalEmployees, setTotalEmployees] = useState<number>(0)
  const [removingParticipantId, setRemovingParticipantId] = useState<
    string | null
  >(null) // ID du employee à retirer
  const [currentInvitations, setCurrentInvitations] = useState<IInvitation[]>( invitations || [])



  const fetchEmployees = async () => {
    try {
      setLoading(true)
      setError(null)

      // Simuler l'appel API pour récupérer les employees avec pagination
      let queryName = ''
      if (model === 'events') {
        queryName = 'employees'
      } else if (model === 'companies') {
        queryName = 'employees'
      }

      const response = await getData(`${model}/${entityId}/${queryName}`, {
        page,
        limit: pageSize,
      })
      const data = await response.data // Assurez-vous que la réponse a le format attendu
      setEmployees(data) // Adapté selon la structure de la réponse
      setTotalEmployees(response.total) // Assurez-vous que `total` est renvoyé par l'API
      setPageSize(50)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    } finally {
      setLoading(false)
    }
  }

  // Fonction pour récupérer les employees
  // Fonction pour récupérer les employees
  useEffect(() => {
    fetchEmployees()
  }, [entityId, page, pageSize])

  const handleAddParticipant = async () => {
    try {
      // Recharger les employees après ajout
      console.log({ filteredEmployees })
      await createData(`companies/${entityId}/employees`, {
        action: 'add',
        employeesIds: filteredEmployees,
      })
      fetchEmployees()
      setPage(1) // Optionnel : revenir à la première page après ajout
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleRemoveParticipant = async (participantId: string) => {
    try {
      setRemovingParticipantId(participantId) // Optionnel : Afficher un indicateur de chargement pour la suppression
      await createData(`companies/${entityId}/employees`, {
        action: 'remove',
        employeesIds: [participantId],
      })
      fetchEmployees()
      setPage(1) // Optionnel : revenir à la première page après suppression
      setRemovingParticipantId(null)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }

  const handleChangeEmployeeRole = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    employee: IParticipant
  ) => {
    console.log(e.target.value, employee)

  setDataStatus('companies',`${entityId}/employees/${employee.userId}`, { role: e.target.value });
  toast.success('Employee role updated successfully')
  }


  const   addInvitation = async (invitation: any) => {
    setCurrentInvitations([invitation, ...currentInvitations])
  }
  if (loading) {
    return <div>Loading employees...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <div className="ViewEmployees p-10">
      <div className="mb-6 flex items-center justify-between">
      <Title as="h3" className="text-lg">
        Employees List
      </Title>

      {/* <ActionIcon
        size="sm"
        variant="text"
        onClick={closeModal}
        className="p-0 text-gray-500 hover:!text-gray-900"
      >
        <PiXBold className="h-[18px] w-[18px]" />
      </ActionIcon> */}
      </div>

      <div className="flex my-2">
      <div className="flex-1">
        <SearhUserInput
        // excludedUserIds={employees.map(employee => employee.userId)}
        onSelectionChange={setFilteredEmployees}
        handleAdd={handleAddParticipant}
        addInvitation={addInvitation}
        company={company}
        />
      </div>
      </div>

      {employees?.length > 0 ? (
      <>
        <table className="table-auto border-collapse border border-gray-400 w-full w-100">
        <thead>
          <tr className="bg-gray-200">
          <th className="border px-4 py-2">N#</th>
          <th className="border px-4 py-2">Name</th>
          <th className="border px-4 py-2">Role</th>
          <th className="border px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee: IParticipant, index: number) => (
          <tr key={employee.id} className="hover:bg-gray-100">
            <td className="border px-4 py-2">
            {totalEmployees - ((page - 1) * pageSize + index)}
            </td>
            <td className="border px-4 py-2">
            <AvatarCard user={employee.user} />
            </td>
            <td className="border px-4 py-2">
            <select
              className="form-control"
              onChange={(e) => handleChangeEmployeeRole(e, employee)}
              name="role"
              id="role"
              defaultValue={employee.role}
            >
              <option value="admin">Admin</option>
              <option value="member">Member</option>
            </select>
            </td>
            <td className="border px-4 py-2">
            <button
              onClick={() => handleRemoveParticipant(employee.userId)}
              disabled={removingParticipantId === employee.id}
              className="px-2 py-1 bg-red-500 btn btn-danger text-white "
            >
              <i className="fa fa-trash mx-2"></i>
              Remove
            </button>
            </td>
          </tr>
          ))}
        </tbody>
        </table>

        {/* Contrôles de pagination */}
        <div className="pagination mt-4">
        {/* TODO(@eakpoli): reimplement this */}
        {/* <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
          className="px-4 py-2 bg-gray-300 text-white  mr-2"
        >
          Previous
        </button>
        <span>
          Page {page} of {Math.ceil(totalEmployees / pageSize)}
        </span>
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= Math.ceil(totalEmployees / pageSize)}
          className="px-4 py-2 bg-gray-300 text-white  ml-2"
        >
          Next
        </button> */}
        </div>
      </>
      ) : (
      <p>No employees found.</p>
      )}


      {currentInvitations && currentInvitations.length > 0 && (
      <div className="mt-6">
        <Title as="h4" className="text-md mb-4">
       ({currentInvitations.length})  Pending Invitations
        </Title>
        <table className="table-auto border-collapse table-striped border border-gray-400 w-full w-100">
      <thead>
        <tr className="bg-gray-200">
        <th className="border px-4 py-2">N#</th>
        <th className="border px-4 py-2">Email</th>
        <th className="border px-4 py-2">Status</th>
        </tr>
      </thead>
      <tbody>
        {currentInvitations.map((invitation: IInvitation, index: number) => (
        <tr key={invitation.id} className="hover:bg-gray-100">
          <td className="border px-4 py-2">{index + 1}</td>
          <td className="border px-4 py-2">{invitation.email}</td>
          <td className="border px-4 py-2">{invitation.status || "PENDING"}</td>
        </tr>
        ))}
      </tbody>
        </table>
      </div>
      )}
    </div>
  )
}

export default ViewEmployees
