import React, { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { refreshAccessToken } from '../api/request-service' // Assure-toi que ce service est correctement implémenté.
import Loading from '../components/Loading/Loading'
import { saveAccessToken } from '../lib/utiles'

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation()
  const [isAuth, setIsAuth] = useState<boolean | null>(null) // `null` pour l'état initial non défini.

  // Fonction pour obtenir la valeur d'un cookie par son nom.
  const getCookie = (name: string) => {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  }

  // useEffect pour vérifier l'authentification lors du chargement du composant.
  useEffect(() => {
    const checkAuth = async () => {
      const accessToken = getCookie('accessToken')
      const refreshToken =
        getCookie('refreshToken') || window.localStorage.getItem('refreshToken')

      if (accessToken && refreshToken) {
        // Si l'access token et le refresh token existent, l'utilisateur est authentifié.
        setIsAuth(true)
      } else if (refreshToken) {
        // Si l'access token est manquant, on tente de le rafraîchir avec le refresh token.
        try {
          const result: any = await refreshAccessToken() // Appelle la fonction qui fait la requête pour rafraîchir le token.

          if (result?.accessToken && result?.refreshToken) {
            // Stocker les nouveaux tokens dans localStorage.
            saveAccessToken(result)

            // L'utilisateur est maintenant authentifié.
            setIsAuth(true)
          } else {
            // Si le rafraîchissement du token échoue, l'utilisateur n'est pas authentifié.
            setIsAuth(false)
          }
        } catch (error) {
          console.error('Erreur lors du rafraîchissement du token:', error)
          setIsAuth(false) // En cas d'erreur, l'utilisateur n'est pas authentifié.
        }
      } else {
        // Si aucun token valide n'est disponible, rediriger vers la page de connexion.
        setIsAuth(false)
      }
    }

    checkAuth() // Appel de la fonction lors du montage du composant.
  }, []) // Ce hook s'exécute uniquement lors du montage du composant.

  // Si isAuth est null, cela signifie que la vérification de l'authentification est en cours.
  if (isAuth === null) {
    return <Loading /> // Optionnel : Afficher un état de chargement pendant la vérification.
  }

  // Redirection vers la page de connexion si non authentifié.
  if (!isAuth) {
    return <Navigate to="/signin" state={{ from: location }} replace />
  }

  // Si l'utilisateur est authentifié, afficher les enfants (le contenu protégé).
  return <>{children}</>
}

export default PrivateRoute
