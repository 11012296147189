import React, { useEffect, useState } from 'react'
import './InvitationFilter.css'
import SelectEventField from '../SelectEventField/SelectEventField'
import { useNavigate } from 'react-router-dom'
import { IInvitationStatus, IInvitationType } from '../../models/IInvitation'
import DateField from '../DateField/DateField'

interface InvitationFilterProps {}

const InvitationFilter: React.FC<InvitationFilterProps> = () => {
  const [filterParms, setFilterParms] = useState({
    startDate: '',
    endDate: '',
    status: 'all',
    eventId: '',
    invitationType: 'all',
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const statusParam = params.get('status') || 'all'
    const startDateParam = params.get('startDate') || ''
    const endDateParam = params.get('endDate') || ''
    const eventIdParam = params.get('eventId') || ''
    const invitationTypeParm = params.get('invitationType') || ''

    setFilterParms({
      startDate: startDateParam,
      endDate: endDateParam,
      status: statusParam,
      eventId: eventIdParam,
      invitationType: invitationTypeParm,
    })
  }, [])

  
  const navigate = useNavigate()

  const updateRequest = (query: any) => {
    console.log(query)
    const params = new URLSearchParams(window.location.search)

    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.set(key, query[key])
      } else {
        params.delete(key)
      }
    })
    navigate(`${window.location.pathname}?${params.toString()}`)
  }

  const handleChangeEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterParms({ ...filterParms, [event.target.name]: event.target.value })
    updateRequest({ [event.target.name]: event.target.value })
  }

  const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterParms({ ...filterParms, [event.target.name]: event.target.value })
    updateRequest({ [event.target.name]: event.target.value })
  }

  const handleDateChange = (data: any) => {
    setFilterParms({ ...filterParms, ...data })
    Object.keys(data).forEach((key) => {
      updateRequest({ [key]: data[key] })
    })
  }

  return (
    <div className="InvitationFilter">
      <div className="container">
        <div className="row mb-3">
          <div className="col">
            <label className="form-label">
              Status:
              <select
                className="form-select"
                value={filterParms.status}
                name="status"
                onChange={handleChangeStatus}
              >
                <option value="all">All</option>
                <option value={IInvitationStatus.ACCEPTED}>Accepted</option>
                <option value={IInvitationStatus.PENDING}>Pending</option>
                <option value={IInvitationStatus.DECLINED}>Rejected</option>
              </select>
            </label>
          </div>
          <div className="col">
            <label className="form-label">
              Invitation Type:
              <select
                className="form-select"
                value={filterParms.invitationType}
                name="invitationType"
                onChange={handleChangeEvent}
              >
                <option value="all">All</option>
                <option value={IInvitationType.INVITED}>Invited</option>
                <option value={IInvitationType.REQUESTED}>Requested</option>
              </select>
            </label>
          </div>
          <div className="col">
            <label className="form-label">Start Date:</label>
            <div className="startDate">
              <DateField
                name="startDate"
                defaultValue={filterParms.startDate}
                onChange={handleDateChange}
              />
            </div>
          </div>
        
          <div className="col">
            <label className="form-label">End Date:</label>
            <div className="endDate">
              <DateField
                name="endDate"
                defaultValue={filterParms.endDate}
                onChange={handleDateChange}
              />
            </div>
          </div>
          <div className="col">
            <SelectEventField
              label="Events : "
              name="eventId"
              defaultOptionName="All events (default)"
              value={filterParms.eventId}
              onChange={handleChangeEvent}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvitationFilter
