import React, { useState, useEffect, useRef } from 'react'

interface OptionFieldsProps {
  name: string
  defaultOptions?: { name: string }[] // Les options par défaut sont des objets avec un champ "name"
  onOptionsChange?: (options: { name: string }[]) => void // Callback pour notifier le parent des changements
}

const OptionFields: React.FC<OptionFieldsProps> = ({
  name,
  defaultOptions = [],
  onOptionsChange,
}) => {
  const [options, setOptions] = useState<{ name: string }[]>(defaultOptions) // Utilisation des objets avec un champ "name"
  const [optionInput, setOptionInput] = useState<string>('')
  const [editingIndex, setEditingIndex] = useState<number | null>(null)

  const initialRender = useRef(true) // Référence pour vérifier si c'est le premier rendu

  // Mettre à jour les options lorsque defaultOptions change
  useEffect(() => {
    setOptions(defaultOptions)
  }, [defaultOptions])

  // Notifier le parent quand les options changent, mais éviter de le faire lors du premier rendu
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false // Ne pas exécuter lors du premier rendu
    } else if (onOptionsChange) {
      onOptionsChange(options)
    }
  }, [options, onOptionsChange])

  const handleAddOption = () => {
    if (optionInput.trim() === '') return

    if (editingIndex !== null) {
      // Modification d'une option existante
      const updatedOptions = options?.map((option, index) =>
        index === editingIndex ? { name: optionInput } : option
      )
      setOptions(updatedOptions)
      setEditingIndex(null)
    } else {
      // Ajout d'une nouvelle option
      setOptions([...options, { name: optionInput }])
    }

    setOptionInput('') // Réinitialisation de l'input
  }

  const handleEditOption = (index: number) => {
    setOptionInput(options[index].name) // Remplir l'input avec la valeur à modifier
    setEditingIndex(index)
  }

  const handleDeleteOption = (index: number) => {
    const updatedOptions = options?.filter((_, i) => i !== index)
    setOptions(updatedOptions)
  }

  return (
    <div className="">
      <div className="card-header">
        <h2>Manage {name}</h2>
      </div>
      <div className="card-body">
        <div className="input-group mb-3">
          <input
            type="text"
            value={optionInput}
            onChange={(e) => setOptionInput(e.target.value)}
            placeholder="Add an option"
            className="form-control"
            aria-label="Add an option"
          />
          <button
            type="button"
            className="btn btn-success"
            onClick={handleAddOption}
          >
            {editingIndex !== null ? 'Update' : 'Add'}
          </button>
        </div>

        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Option</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {options &&
              options?.map((option, index) => (
                <tr key={index}>
                  <td>{option.name}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-warning mx-1"
                      onClick={() => handleEditOption(index)}
                      aria-label={`Edit option ${option.name}`}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger mx-1"
                      onClick={() => handleDeleteOption(index)}
                      aria-label={`Delete option ${option.name}`}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OptionFields
