import React from 'react'
import './DateField.css'
import { DatePicker } from 'antd'
//import 'antd/dist/antd.css'
import  moment, { Moment } from 'moment'

interface DateFieldProps {
    name: string
    onChange: (date: any) => void
    defaultValue?: string
}

const DateField: React.FC<DateFieldProps> = ({name, onChange, defaultValue }) => {
 
    const handleChange = (data: Moment) =>{
        const result = {[name]: data ? data?.format('YYYY-MM-DD') : '' }
        onChange(result)
        console.log(result)
    }

    return <DatePicker 
    onChange={handleChange} 
    defaultValue={defaultValue ? moment(defaultValue).toDate() : undefined} 
    />
}

export default DateField
