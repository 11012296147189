import React, { useState, useRef, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

interface ChooseImageProps {
  name: string
  currentFiles: string[] | string
  onChange: (files: { [key: string]: File[] }) => void
  isMany: boolean
}

const ChooseImage: React.FC<ChooseImageProps> = ({
  name,
  onChange,
  isMany,
  currentFiles,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [imagePreviews, setImagePreviews] = useState<string[]>([]) // Init empty, will update in useEffect

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  // Effect to update image previews when currentFiles changes
  useEffect(() => {
    if (currentFiles && currentFiles.length > 0) {
      if (currentFiles instanceof Array) {
        setImagePreviews(currentFiles) // Assuming currentFiles are valid image URLs
      } else {
        setImagePreviews([currentFiles])
      }
    }
  }, [currentFiles])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || [])

    // Combiner les nouveaux fichiers avec les fichiers sélectionnés existants si 'isMany' est vrai
    let updatedFiles: File[] = []
    if (isMany) {
      updatedFiles = [...selectedFiles, ...newFiles] // Concaténer les nouveaux fichiers avec les fichiers déjà sélectionnés
    } else {
      updatedFiles = newFiles.length > 0 ? [newFiles[0]] : [] // Si 'isMany' est faux, remplacer avec un seul fichier
    }

    setSelectedFiles(updatedFiles)

    // Créer les prévisualisations pour les nouveaux fichiers et les combiner avec les anciennes si nécessaire
    const newPreviews = newFiles.map((file) => URL.createObjectURL(file))
    setImagePreviews((prevPreviews) =>
      isMany ? [...prevPreviews, ...newPreviews] : newPreviews
    ) // Concaténer ou remplacer

    onChange({ [name]: updatedFiles })
  }

  const handleRemoveImage = (index: number) => {
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index)
    const newPreviews = imagePreviews.filter((_, i) => i !== index)

    setSelectedFiles(newSelectedFiles)
    setImagePreviews(newPreviews)
    onChange({ [name]: newSelectedFiles })
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return

    const items = Array.from(selectedFiles)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    const previews = Array.from(imagePreviews)
    const [reorderedPreview] = previews.splice(result.source.index, 1)
    previews.splice(result.destination.index, 0, reorderedPreview)

    setSelectedFiles(items)
    setImagePreviews(previews)
    onChange({ [name]: items })
  }

  return (
    <div className="choose-image-container">
      <button
        type="button"
        onClick={handleButtonClick}
        className="btn btn-primary"
      >
        {isMany ? 'Choose images' : 'Choose image'}
      </button>

      <input
        type="file"
        accept="image/*"
        multiple={isMany}
        onChange={handleFileChange}
        className="file-input"
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <div
              className="image-preview-container row mt-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {imagePreviews.map((preview: any, index: number) => (
                <Draggable
                  key={index}
                  draggableId={`draggable-${index}`}
                  index={index}
                >
                  {(result: any) => (
                    <div
                      ref={result.innerRef}
                      {...result.draggableProps}
                      {...result.dragHandleProps}
                      className="image-preview col-md-4 position-relative"
                    >
                      <img
                        src={
                          preview instanceof File
                            ? URL.createObjectURL(preview)
                            : preview
                        }
                        alt={`preview-${index}`}
                        className="preview-image"
                        width={'100%'}
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveImage(index)}
                        className="remove-image-button position-absolute top-0 start-0 btn btn-danger"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default ChooseImage
