import { useNavigate } from 'react-router-dom'
import { siteConfig } from '../../config/site-config'
import { getSearchStringByModel } from '../../lib/utiles'
import CustomPagination from '../CustomPagination/CustomPagination'
import SearchForm from '../SearchForm/SearchForm'

interface Props {
  model: string // Modèle de données
  limitOptions: number[] // Options de limites dynamiques
  result: any // Définissez le type approprié pour votre résultat
  handlePageChange: (pageNumber: number) => void
  handleChangeLimit: (event: React.ChangeEvent<HTMLSelectElement>) => void
  limit: number // Valeur actuelle de la limite
  hasSearch?: boolean // Afficher le champ de recherche
}

const DataPagination: React.FC<Props> = ({
  model,
  limitOptions,
  result,
  handlePageChange,
  handleChangeLimit,
  limit,
  hasSearch = false,
}) => {
  const tenant = siteConfig.tenant
  const navigate = useNavigate()

  const handleSearch = (value: string) => {
    const queryParams = new URLSearchParams(location.search)
    const paramsBuild = getSearchStringByModel(model, queryParams, value)
    navigate(`${location.pathname}?${paramsBuild}`)
  }

  return (
    <div className="data-pagination d-flex justify-content-between align-items-center my-1">
      <CustomPagination
        pageInfo={result.meta}
        onPageChange={handlePageChange}
      />
      <div>
        {tenant && hasSearch && (
          <SearchForm onSearch={handleSearch} placeholder="Search..." />
        )}
      </div>
      <div>
        <select
          onChange={handleChangeLimit}
          value={limit}
          className="form-control"
        >
          {limitOptions.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default DataPagination
