import React, { FC, useState } from 'react'
import './ImgComponent.css'

interface ImgComponentProps {
  src: string
  alt: string
  style: Record<string, any>
}

const ImgComponent: FC<ImgComponentProps> = ({ src, alt, style }) => {
  const [imageUrl, setImageUrl] = useState(src)

  return (
    <img
      src={imageUrl}
      alt={alt}
      style={style}
      onError={() => setImageUrl('/faacer.png')}
    />
  )
}

export default ImgComponent
