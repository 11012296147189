import React, { FC, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import './Error404.css' // Import your custom styles

interface Error404Props {}

const Error404: FC<Error404Props> = () => {
  const navigate = useNavigate()

  const handleGoHome = () => {
    navigate('/') // Navigate back to the homepage
  }

  return (
    <Fragment>
      <div className="error-404 d-flex align-items-center justify-content-center min-vh-100">
        <div className="text-center">
          <h1 className="display-1">404</h1>
          <h2>Page Not Found</h2>
          <p>Sorry, the page you are looking for does not exist.</p>
          <button className="btn btn-primary" onClick={handleGoHome}>
            Go to Homepage
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default Error404
