import React, { useState } from 'react'

interface ReadMoreProps {
  text: string
  maxLength: number
}

const ReadMore: React.FC<ReadMoreProps> = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded)
  }

  const createMarkup = (html: string) => {
    return { __html: html }
  }

  // Display truncated text if the length exceeds `maxLength`
  const displayedText =
    isExpanded || text?.length <= maxLength
      ? text
      : `${text?.slice(0, maxLength)}...`

  return (
    <div>
      <div dangerouslySetInnerHTML={createMarkup(displayedText)} />
      {text?.length > maxLength && (
        <button onClick={toggleReadMore} className="btn btn-success">
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      )}
    </div>
  )
}

export default ReadMore
