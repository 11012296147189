import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

interface InvitationFormProps {
  onCancel: () => void
  onSend: (emails: string[]) => void
}

const InvitationForm: React.FC<InvitationFormProps> = ({
  onCancel,
  onSend,
}) => {
  const [emails, setEmails] = useState<string[]>([''])
  const [errors, setErrors] = useState<string[]>([])

  const handleEmailChange = (index: number, value: string) => {
    const newEmails = [...emails]
    newEmails[index] = value
    setEmails(newEmails)
  }

  const handleAddEmail = () => {
    setEmails([...emails, ''])
  }

  const handleRemoveEmail = (index: number) => {
    const newEmails = emails.filter((_, i) => i !== index)
    setEmails(newEmails)
  }

  const validateEmails = () => {
    const newErrors = emails.map(email => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email) ? '' : 'Invalid email address'
    })
    setErrors(newErrors)
    return newErrors.every(error => error === '')
  }

  const handleSend = () => {
    if (validateEmails()) {
      onSend(emails)
      onCancel()
    }
  }

  return (
    <div className="container mt-4">
      <div className="card-">
        <div className="card-body-">
          {emails.map((email, index) => (
            <div className="mb-3" key={index}>
              <label htmlFor={`email-${index}`} className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id={`email-${index}`}
                value={email}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                placeholder="Enter email"
              />
              {errors[index] && (
                <div className="text-danger">{errors[index]}</div>
              )}
              {emails.length > 1 && (
                <button
                  type="button"
                  className="btn btn-danger mt-2"
                  onClick={() => handleRemoveEmail(index)}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <hr />
          <div className="action">
            <button
              type="button"
              className="btn btn-secondary btn-warning me-2"
              onClick={handleAddEmail}
            >
              Add Another Email
            </button>
            <button
              className="btn btn-primary me-2"
              onClick={handleSend}
            >
              Send Invitations
            </button>
            <button className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvitationForm