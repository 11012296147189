import React, { FC, useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import './UserSelector.css' // Assurez-vous que ce fichier existe
import { IUser } from '../../models/IUser'
import { ICompany } from '../../models/ICompany'
import { getData } from '../../api/request-service'

interface SearhUserOrCompanyProps {
  onSelectionChange: (selectedIds: string[]) => void
  handleAdd: () => void
}

const SearhUserOrCompany: FC<SearhUserOrCompanyProps> = ({
  onSelectionChange,
  handleAdd,
}) => {
  const [dataSet, setDataSet] = useState<(IUser | ICompany)[]>([])
  const [filteredResults, setFilteredResults] = useState<(IUser | ICompany)[]>(
    []
  )
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  // Récupérer les utilisateurs et les entreprises
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // Fetch les utilisateurs et les entreprises en parallèle
        
        const result = await getData('search/companies/or/user?query='); // Assurez-vous que cette route existe
        setDataSet(result.data)
      } catch (error) {
        console.error('Failed to fetch data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])



  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    const result = await getData(`search/companies/or/user?query=${query}`);
    if(result.success){
      setFilteredResults(result.data)
    }
  }

  // Ajouter un élément (utilisateur ou entreprise) à la sélection
  const handleSelectItem = (item: IUser | ICompany) => {
    setSelectedIds((prevIds) => {
      const newIds = [...prevIds, item.id]
      onSelectionChange(newIds)
      return newIds
    })
    setSearchQuery('') // Réinitialiser la barre de recherche après sélection
  }

  // Retirer un élément (utilisateur ou entreprise) de la sélection
  const handleRemoveItem = (itemId: string) => {
    setSelectedIds((prevIds) => {
      const newIds = prevIds.filter((id) => id !== itemId)
      onSelectionChange(newIds)
      return newIds
    })
  }

  return (
    <div className="SearhUserOrCompany">
      <div className="search-box mb-4 d-flex gap-1">
        <div className="flex-1">
          <input
            type="search"
            defaultValue={searchQuery}
            onChange={handleSearch}
            placeholder="Search user or company"
            className="border w-100 px-4 py-2 w-full form-control"
          />
        </div>
        <button className="btn btn-success" onClick={handleAdd}>
          <i className="fa fa-plus mx-2"></i>
          Add
        </button>
      </div>

      {loading && <div>Loading users and companies...</div>}

      {filteredResults.length > 0 && (
        <div className="autocomplete-results">
          <ul className="list-none p-0">
            {filteredResults.map((item: IUser | ICompany) => (
              <li
                key={item.id}
                className="cursor-pointer p-2 hover:bg-gray-100"
                onClick={() => handleSelectItem(item)}
              >
                {'email' in item ? 
                 `${item?.profile?.givenName} ${item?.profile?.familyName}` : 
                 `${item.name}`}

                    <span className={`badge rounded px-2 py-1 ml-2 ${'email' in item ? 'bg-danger text-green-800' : 'bg-purple-200 text-purple-800'}`}>
                    {'email' in item ? 'User' : 'Company'}
                  </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedIds.length > 0 && (
        <div className="selected-items mt-4">
          {[...dataSet]
            .filter((item) => selectedIds.includes(item.id))
            .map((item) => (
                <div
                key={item.id}
                className={`badge rounded px-2 py-1 flex items-center mb-2 ${'email' in item ? 'bg-danger text-blue-800' : 'bg-green-200 text-green-800'}`}
                >
                {'email' in item ? 
                `${item?.profile?.givenName} ${item?.profile?.familyName}` : 
                `${item.name}`}
                <FaTimes
                  className="ml-2 cursor-pointer"
                  onClick={() => handleRemoveItem(item.id)}
                />
                </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default SearhUserOrCompany
