import React from 'react'

interface PreviewImageOrVideoProps {
  files: string[] // URLs ou fichiers
}

// Styles en ligne pour le conteneur et les éléments
const styles: any = {
  container: {
    display: 'flex',
    overflowX: 'scroll',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
  },
  item: {
    minWidth: '200px',
    margin: '0 10px',
  },
  media: {
    width: '100%',
    borderRadius: '8px',
  },
}

const PreviewImageOrVideo: React.FC<PreviewImageOrVideoProps> = ({ files }) => {
  return (
    <div className="preview-container" style={styles.container}>
      {files.map((file: string, index) => {
        const isVideo =
          typeof file === 'string' &&
          (file.endsWith('.mp4') || file.endsWith('.mov'))

        return (
          <div key={index} className="preview-item" style={styles.item}>
            {isVideo ? (
              <video controls style={styles.media} src={file as string} />
            ) : (
              <img src={file} alt={`preview-${index}`} style={styles.media} />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default PreviewImageOrVideo
