import React, { FC, useEffect, Fragment, useState } from 'react'
import './SelectEventField.css'
import { getData } from '../../api/request-service'
import { IEvent } from '../../models/IEvent'

interface SelectEventFieldProps {
  label: string
  name: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  defaultOptionName?: string
}

const SelectEventField: FC<SelectEventFieldProps> = ({
  label,
  name,
  value,
  onChange,
  defaultOptionName
}) => {
  const [events, setEvents] = useState<IEvent[]>([])

  useEffect(() => {
    const fetchEvents = async () => {
      const result = await getData('events')
      setEvents(result.data)
    }
    if (!document.querySelector('script[src="https://cdn.jsdelivr.net/npm/select2@4.0.13/dist/js/select2.full.min.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/select2@4.0.13/dist/js/select2.full.min.js';
      script.async = true;
      document.body.appendChild(script);
    }
 

    fetchEvents()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event)
   
  }

  return (
    <Fragment>
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        value={value}
        onChange={handleChange}
        className="form-select"
        id="select-field"
      >
        <option value="">{defaultOptionName || 'Select Event'}</option>
        {events.map((event, index) => (
          <option key={index} value={event.id}>
            {event.name}
          </option>
        ))}
      </select>
    </Fragment>
  )
}

export default SelectEventField
