import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import './ModelTable.css'
import FormModel from '../FormModel/FormModel'
import { IColumn } from '../../models/IColumn'
import { useLocation, useNavigate } from 'react-router-dom'
import ViewModel from '../ViewModel/ViewModel'
import { getItem, setItem } from '../../helpers/localsorage.service'
import { ucfirst } from '../../helpers/utiles'
import DeleteConfirmModal from '../DeleteConfirmModal/DeleteConfirmModal'
import {
  createData,
  deleteData,
  getData,
} from '../../api/request-service'
import { DataType, getColumns } from './columns'
import { renderHTMLContent } from '../../helpers/tabe-filter'
import { getSingular } from '../../lib/utiles'
import Papa from 'papaparse'
import { siteConfig } from '../../config/site-config'
import DataPagination from '../DataPagination/DataPagination'
import DropFileModal from '../DropFileModal/DropFileModal'
import { toast } from 'react-toastify'
import ModelFilter from '../ModelFilter/ModelFilter'
import SearchModelData from '../SearchModelData/SearchModelData'

interface ModalTableProps {
  page: number
  limit: number
  model: string
}

const ModalTable: FC<ModalTableProps> = ({ model, page, limit }) => {
  const [columns, setColumns] = useState<IColumn[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showViewModal, setShowViewModal] = useState<boolean>(false)
  const [currentData, setCurrentData] = useState<any>(null)
  const [modal, setModal] = useState<boolean>(false)
  const [result, setResult] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const [selectedData, setSelectedData] = useState<any[]>([])
  const navigate = useNavigate()
  const location = useLocation()
  const tenant = siteConfig.tenant
  const [uploadingFile, setUploadingFile] = useState<boolean>(false)
  const limitOptions = [5, 10, 20, 50, 100]
  const params = new URLSearchParams(location.search)
  const {status, startDate,searchTag, endDate, eventId, invitationType} = Object.fromEntries(params.entries())
  
  //  get  query     filter
  
  const runLocalData = async () => {
    let response
    setSelectedData([])
    const data = Object.fromEntries(params.entries())
    
    response = await getData(model, { page, limit , ...data})
    

    setItem('adminPage', page)
    setItem('adminLimit', limit)

    if (response.data) {
      setResult(response)
      console.log({ response })

      const storedColumns: any = getItem(`${model}Column`)
      if (storedColumns && storedColumns?.length) {
        setColumns(storedColumns)
      } else if (response.data.length) {
        const initialColumns = Object.keys(response.data[0])
          .filter((key) => key !== 'id')
          .map((name, index) => ({ name, checked: index <= 2 }))
        setColumns(initialColumns)
        setItem(`${model}Column`, initialColumns)
      }
    }
  }



  useEffect(() => {
    window.scrollTo(0, 0)
    runLocalData()
  }, [page, limit,searchTag, status, startDate, endDate, eventId, invitationType])

  // const handleChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   name: string
  // ) => {
  //   const updatedColumns = columns.map((col) =>
  //     col.name === name ? { ...col, checked: event.target.checked } : col
  //   )
  //   setColumns(updatedColumns)
  //   setItem(`${model}Column`, updatedColumns)
  // }

  const handlePageChange = (pageNumber: number) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', String(pageNumber))
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  const handleChangeLimit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLimit = parseInt(event.target.value, 10)
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('limit', String(newLimit))
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  // const handleSearch = (params: string, tagValue: string) => {
  //   const queryParams = new URLSearchParams(location.search)
  //   queryParams.set('query', params)
  //   queryParams.set('tag', tagValue)
  //   navigate(`${location.pathname}?${queryParams.toString()}`)
  // }

  const handleDeleteConfirm = async () => {
    if (model && currentData) {
      await deleteData(model, currentData.id)
      setResult((prev: any) => ({
        ...prev,
        data: prev.data.filter((item: any) => item.id !== currentData.id),
      }))
      setShowDeleteModal(false)
      toast.success(`${ucfirst(getSingular(model))} deleted successfully`)
    }
  }

  const handleEditClick = (data: any) => {
    setCurrentData(data)
    setModal(true)
  }

  const handleViewClick = (data: any) => {
    setCurrentData(data)
    setShowViewModal(true)
  }

  const handleDeleteClick = (data: any) => {
    console.log({ data })

    setCurrentData(data)
    setShowDeleteModal(true)
  }
  const managerIsDo =
    tenant === 'manager' &&
    (model === 'events' || model === 'companies' || model === 'invitations')

  const handleCloseFormModal = () => {
    setCurrentData(null)
    setModal(false)
    setShowViewModal(false)
    setShowDeleteModal(false)
    runLocalData()
  }

  const renderActionByTenant = (data: any) => (
    <div className="d-flex">
      <button
        className="btn btn-success me-1"
        onClick={() => handleViewClick(data)}
      >
        <i className="fas fa-eye"></i>
      </button>
      {tenant === 'admin' && (
        <>
          <button
            className="btn btn-warning me-1"
            onClick={() => handleEditClick(data)}
          >
            <i className="fas fa-edit"></i>
          </button>
          <button
            className="btn btn-danger"
            onClick={() => handleDeleteClick(data)}
          >
            <i className="fas fa-trash"></i>
          </button>
        </>
      )}
      {managerIsDo && (
        <>
          <button
            className="btn btn-warning me-1"
            onClick={() => handleEditClick(data)}
          >
            <i className="fas fa-edit"></i>
          </button>
          <button
            className="btn btn-danger"
            onClick={() => handleDeleteClick(data)}
          >
            <i className="fas fa-trash"></i>
          </button>
        </>
      )}
    </div>
  )

  const handleSelectData = (e: ChangeEvent<HTMLInputElement>, data: any) => {
    const { checked, name } = e.target

    if (name === 'selectAll') {
      if (checked) {
        setSelectedData(data as any[])
      } else {
        setSelectedData([])
      }
    } else {
      if (checked) {
        setSelectedData((prevSelected) => [...prevSelected, data as any])
      } else {
        setSelectedData((prevSelected) =>
          prevSelected.filter((item) => item.id !== (data as any).id)
        )
      }
    }
  }

  const renderTableHeader = () => (
    <thead>
      <tr>
        <th>
          <input
            type="checkbox"
            name="selectAll"
            id="selectAll"
            onChange={(e) => handleSelectData(e, result.data)}
            defaultChecked={
              result.data.length > 0 &&
              result.data.every((item: any) =>
                selectedData.some((selected) => selected.id === item.id)
              )
            }
          />
        </th>
        <th>#</th>
        {getColumns(model).map((column: any, index: number) => (
          <th key={index + column.title}>{ucfirst(column.title)}</th>
        ))}
        <th>Actions</th>
      </tr>
    </thead>
  )

  const renderTableBody = () => {
    // Vérifiez si les données sont disponibles
    if (!result || !result.data || result.data.length === 0) return null

    // Calcul des indices de pagination
    const startIndex =
      (result.meta.totalItems || 0) -
      (result.meta.currentPage - 1) * result.meta.itemsPerPage

    console.log({ searchTag })

    return (
      <tbody>
        {
        result.data.map((data: any, index: number) => (
          <tr key={data.id}>
            <td>
              <input
                className=" "
                type="checkbox"
                onChange={(e) => handleSelectData(e, data)}
                checked={selectedData.map((t) => t.id).includes(data.id)}
              />
            </td>{' '}
            <td>{startIndex - index}</td>{' '}
            {/* Calcul de l'index pour afficher en décroissant */}
            {getColumns(model).map((column: DataType) => (
              <td key={'data-' + column.key}>
                {renderHTMLContent(data, column.key, model)}
              </td>
            ))}
            <td>{renderActionByTenant(data)}</td>
          </tr>
        ))
        }
      </tbody>
    )
  }

  const handleSelectFile = async (file: File, modelData: any) => {
    if (file.type !== 'text/csv') {
      setError('Only csv file is allowed')
      return
    }
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results: { data: any[]; errors: any[]; meta: any }) => {
        try {
          let formattedData: any[] = []
          if (model === 'users') {
            formattedData = results.data.map((row: any) => ({
              email: row.email,
              phone: row.phone,
              givenName: row.firstName,
              familyName: row.lastName,
            }))
          }
          if (model === 'events') {
            formattedData = results.data.map((row: any) => ({
              name: row.name,
              description: row.description,
              placeName: row.placeName,
              formattedAddress: row.formattedAddress,
              latitude: parseFloat(row.latitude),
              longitude: parseFloat(row.longitude),
              isVisible: row.isVisible || false,
              maximumParticipantCapacity:
                parseInt(row.maximumParticipantCapacity) || 0,
              private: row.private,
              startsAt: row.startDate || row.startsAt,
              endsAt: row.endDate || row.endsAt,
              updatedAt: row.updatedAt || new Date().toISOString(),
              createdAt: row.createdAt || new Date().toISOString(),
              links: row.links || {},
              tables:
                row.tables.split(',').map((name: string) => ({ name: name })) ||
                [],
            }))
          }
          if (model === 'companies') {
            formattedData = results.data.map((row: any) => ({
              name: row.name,
              description: row.description,
              imageUrl: row.imageUrl,
              coverUrl: row.coverUrl,
              locality: row.locality,
              industry: row.industry,
              // lookingFor: row.lookingFor,
              // foundedYear: row.foundedYear,
              size: row.size,
              links: row.links || {},
              createdAt: row.createdAt || new Date().toISOString(),
              updatedAt: row.updatedAt || new Date().toISOString(),
            }))
          }
          if (model === 'invitations') {
            formattedData = results.data.map((row: any) => ({
              eventId: modelData.eventId,
              firstName: row.firstName,
              lastName: row.lastName,
              email: row.email,
              phone: row.phone,
              metadata: row.metadata || {},
              createdAt: row.createdAt || new Date().toISOString(),
              updatedAt: row.updatedAt || new Date().toISOString(),
            }))
          }

          console.log({ formattedData })

          formattedData?.map(async (data) => {
            await createData(`${model}`, data)
            toast.success(`${ucfirst(getSingular(model))} uploaded successfully`)
          })
        } catch (err) {
          toast.error('An error occurred while uploading csv file')
          setError(
            err instanceof Error ? err.message : 'An unknown error occurred'
          )
        }
      },
      error: (parseError: Error) => {
        console.error('Error parsing CSV:', parseError)
        setError('Error parsing CSV file.')
      },
    })
  }

  return (
    <div className="ModalTable w-100 p-2">
      <h2>
        <i className="fas fa-table"></i> {ucfirst(model)}
      </h2>
      <DeleteConfirmModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteConfirm}
      />
      {modal && (
        <FormModel
          model={model}
          columns={columns}
          modelId={currentData?.id}
          handleClose={handleCloseFormModal}
        />
      )}
      {showViewModal && (
        <ViewModel
          modelId={currentData?.id}
          model={model}
          handleClose={handleCloseFormModal}
        />
      )}

      <div className="d-flex justify-content-end my-1">
        {(tenant === 'admin' || managerIsDo) && (
          <>
            <button className="btn btn-primary" onClick={() => setModal(true)}>
              <i className="fa fa-plus mx-2"></i> Add {ucfirst(getSingular(model))}
            </button>
            <button
              className="btn btn-primary ms-2"
              onClick={() => setUploadingFile(true)}
            >
              <i className="fa fa-file-upload mx-2"></i> Upload Csv
            </button>
            <DropFileModal
              show={uploadingFile}
              model={model}
              onClose={() => setUploadingFile(false)}
              onUpload={handleSelectFile}
            />
          </>
        )}
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      {result?.data ? (
        <>
          <div className="content w-100">
            <div className="card mb-4">
              <div className="card-header">
                <DataPagination
                  model={model}
                  limitOptions={limitOptions}
                  result={result}
                  handlePageChange={handlePageChange}
                  handleChangeLimit={handleChangeLimit}
                  limit={limit}
                />
                 <SearchModelData model={model} />
              </div>
              <div className="card-body">
                <ModelFilter model={model} />
                <div className="table-responsive">
                  {result?.data.length ? (
                    <table className="table table-striped table-bordered" id='dataTable'>
                      {renderTableHeader()}
                      {renderTableBody()}
                    </table>
                  ) : (params.get('searchTag') || startDate || endDate || eventId || status) ? (
                    <div className="card p-5">
                      {
                      searchTag ? (
                        <>
                        <i className="fas fa-search"></i> No results found for <strong>{params.get('searchTag')}</strong>
                        </>
                      ) : null
                      }
                      {
                      (startDate || endDate || eventId || status) && (
                        <div>
                        <i className="fas fa-info-circle"></i> No results found for the applied filters.
                        </div>
                      )
                      }
                    </div>
                  ) : (
                    <div className="card p-5">
                      <i className="fas fa-info-circle"></i> No data available.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <DataPagination
            model={model}
            limitOptions={limitOptions}
            result={result}
            handlePageChange={handlePageChange}
            handleChangeLimit={handleChangeLimit}
            limit={limit}
          />
        </>
      ) : null}
    </div>
  )
}

export default ModalTable
