import React, { ChangeEvent } from 'react'
import { ucfirst } from '../../helpers/utiles'
import ChooseImage from '../ChooseImage/ChooseImage'
import ChooseVideo from '../ChooseVideo/ChooseVideo'
import { string } from 'yup'

// Sous-composant pour l'input standard (text, number, etc.)
interface StandardInputProps {
  type: string
  name: string
  value: string | number | Date
  placeholder?: string
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const StandardInput: React.FC<StandardInputProps> = ({
  type,
  name,
  value,
  placeholder,
  handleInputChange,
}) => {
  return (
    <input
      type={type}
      className="form-control"
      name={name}
      placeholder={placeholder}
      defaultValue={
        value instanceof Date ? value.toISOString().substring(0, 16) : value
      }
      onChange={handleInputChange}
    />
  )
}

// Composant principal InputField
interface InputFieldProps {
  type: string
  label: string
  name: string
  errorMessage?: string
  placeholder?: string
  value: string | number | boolean | FileList | Date | string[]
  onChange: (value: any) => void
}

const InputField: React.FC<InputFieldProps> = ({
  type,
  label,
  name,
  value,
  errorMessage,
  placeholder,
  onChange,
}) => {
  return (
    <div className="form-group py-1">
      <label>{ucfirst(label)}</label>
      {type === 'checkbox' ? (
        <input
          className="form-control"
          type={type}
          name={name}
          checked={value as boolean}
          onChange={onChange}
        />
      ) : name.includes('image') || name.includes('cover') ? (
        <>
          <ChooseImage
            name={name}
            currentFiles={
              value instanceof string ? [value as string] : (value as string[])
            }
            onChange={onChange}
            isMany={name.endsWith('s')}
          />
        </>
      ) : name.includes('video') ? (
        <>
          <ChooseVideo
            name={name}
            currentFiles={
              value instanceof string ? [value as string] : (value as string[])
            }
            onChange={onChange}
            isMany={name.endsWith('s')}
          />
        </>
      ) : (
        <StandardInput
          type={type}
          name={name}
          value={value as string | number | Date}
          placeholder={placeholder}
          handleInputChange={onChange}
        />
      )}

      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </div>
  )
}

export default InputField
