import React, { FC, useEffect, Fragment, useState } from 'react'
import { getData } from '../api/request-service'
import { IEvent } from '../models/IEvent'

interface SelectParticipantCategoriesProps {
  label: string
  name: string
  value: string
  eventId: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const SelectParticipantCategories: FC<SelectParticipantCategoriesProps> = ({
  label,
  name,
  value,
  eventId,
  onChange,
}) => {
  const [categories, setCategories] = useState<IEvent[]>([])

  useEffect(() => {
    const fetchParticipantCategories = async () => {
      const result = await getData(`events/${eventId}/participant-categories`)
      if(result.success) setCategories(result.data)
    }

    fetchParticipantCategories()
  }, [eventId])

  return (
    <Fragment>
      <>
        {
          categories.length === 0 ? (
            <p className="text">No categories found</p>
          ):
          <div>
            <label htmlFor={name}>{label}</label>
            <select
              name={name}
              value={value}
              onChange={onChange}
              className="form-control"
            >
              <option value="">Select category</option>
              {categories?.map((category, index) => (
                <option key={index} value={category.id}>
                  {category.name} ({category.participants.length})
                </option>
              ))}
            </select>
          </div>
        }
      </>
    </Fragment>
  )
}

export default SelectParticipantCategories
