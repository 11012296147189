import { siteConfig } from '../config/site-config'
import { clearUser } from '../helpers/utiles'

// Helper function to construct the API URL with query parameters
function constructUrl(baseUrl: string, params?: Record<string, any>): string {
  if (!params) {
    return baseUrl
  }
  if (!params?.page) {
    params.page = 1
  }
  if (!params?.limit) {
    params.limit = 1000
  }

  const queryString = new URLSearchParams(params).toString()
  return `${baseUrl}?${queryString}`
}

// Base API URL construction function
function getApiUrl(entityName: string): string {
  const tenant = siteConfig.tenant
  return `${siteConfig.API_URL}/v1/${tenant}/${entityName}`
}

// Utility function to retrieve the token from localStorage
function getToken(): string | null {
  if (typeof window !== 'undefined') {
    const cookies = document.cookie.split('; ') // Récupère les cookies sous forme de tableau
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=')
      if (name === 'accessToken') {
        // Vérifie si le cookie s'appelle 'accessToken'
        return decodeURIComponent(value) // Retourne la valeur du cookie 'accessToken'
      }
    }
    // Redirection si aucun token n'est trouvé
    window.location.href = '/signin'
  }

  // Retourne null si on est côté serveur ou si aucun token n'a été trouvé
  return null
}

function getRefreshToken(): string | null {
  if (typeof window !== 'undefined') {
    const cookies = document.cookie.split('; ') // Récupère les cookies sous forme de tableau
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=')
      if (name === 'refreshToken') {
        // Vérifie si le cookie s'appelle 'accessToken'
        return decodeURIComponent(value) // Retourne la valeur du cookie 'accessToken'
      }
    }
  }
  return null // Si le token n'est pas trouvé ou si pas dans un environnement navigateur, retourne null
}

/**
 * Fetch a list of data (GET)
 * @param entityName - The name of the entity to fetch (e.g., 'users')
 * @param params - Optional query parameters to include in the URL
 * @returns {Promise<any>} - Returns a promise with the fetched data
 */
export async function getData(
  entityName: string,
  params?: Record<string, any>
): Promise<any> {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

  const API_URL = constructUrl(getApiUrl(entityName), params)

  try {
    const response = await fetch(API_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to fetch data for ${entityName}`
      )
    }

    return await response.json() // Return the fetched data
  } catch (error) {
    console.error(`Error fetching ${entityName}:`, error)
    throw error
  }
}

//  signin function
export async function signin(data: Record<string, any>): Promise<any> {
  const tenant = siteConfig.tenant
  const API_URL = `${siteConfig.API_URL}/v1/auth/signin/email/by/${tenant}`

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      throw new Error(`Error ${response.status}: Failed to signin`)
    }

    return await response.json()
  } catch (error) {
    console.error(`Error signin:`, error)
    throw error
  }
}

export async function refreshAccessToken(): Promise<void> {
  const API_URL = `${siteConfig.API_URL}/v1/auth/refresh`

  const token = getRefreshToken()

  if (token === null) {
    window.location.href = window.location.origin + '/signin'
  }

  const data = { refreshToken: token }

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    return await response.json()
  } catch (error) {
    clearUser()
    console.error('Erreur:', error)
  }
}

export async function signinManager(data: Record<string, any>): Promise<any> {
  const API_URL = `${siteConfig.API_URL}/v1/auth/signin/email/by/manager`

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      throw new Error(`Error ${response.status}: Failed to signin`)
    }

    return await response.json()
  } catch (error) {
    console.error(`Error signin:`, error)
    throw error
  }
}

//  verify email Code function
export async function verifyClientToken(token: string): Promise<any> {
  const API_URL = `${siteConfig.API_URL}/v1/auth/signin/verify/client/token`

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })

    return await response.json()
  } catch (error) {
    console.error(`Error verifying token :`, error)
    throw error
  }
}

export async function resendAuthCodeByEmail(token: string): Promise<any> {
  const API_URL = `${siteConfig.API_URL}/v1/auth/signin/resend/auth-code`

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })

    return await response.json()
  } catch (error) {
    console.error(`Error verifying token :`, error)
    throw error
  }
}


export async function verifyEmailCode(data: Record<string, any>): Promise<any> {
  const API_URL = `${siteConfig.API_URL}/v1/auth/signin/verify/email/code`

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      throw new Error(`Error ${response.status}: Failed to verify email code`)
    }

    return await response.json()
  } catch (error) {
    console.error(`Error verifying email code:`, error)
    throw error
  }
}

/**
 * Fetch a specific entity by ID (GET)
 * @param entityName - The name of the entity to fetch (e.g., 'users')
 * @param id - The ID of the entity to fetch
 * @returns {Promise<any>} - Returns a promise with the fetched entity data
 */
export async function getDataById(
  entityName: string,
  id: string
): Promise<any> {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

  const API_URL = `${getApiUrl(entityName)}/${id}` // Construction de l'URL avec l'ID

  try {
    const response = await fetch(API_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to fetch ${entityName} with ID ${id}`
      )
    }

    return await response.json() // Retourne l'entité trouvée par l'ID
  } catch (error) {
    console.error(`Error fetching ${entityName} with ID ${id}:`, error)
    throw error
  }
}

/**
 * Update the status of a specific entity (PATCH/PUT)
 * @param entityName - The name of the entity to update (e.g., 'users')
 * @param id - The ID of the entity to update
 * @param statusData - An object containing the status field(s) to update (e.g., { isActive: true })
 * @returns {Promise<any>} - Returns a promise with the updated entity data
 */
export async function setDataStatus(
  entityName: string,
  id: string,
  statusData: { isActive?: boolean; role?: string; status?: string }
): Promise<any> {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

  const API_URL = `${getApiUrl(entityName)}/${id}/status` // Construction de l'URL avec l'ID

  try {
    const response = await fetch(API_URL, {
      method: 'POST', // Utilisation de PATCH ou PUT selon l'API REST (ici PATCH pour une mise à jour partielle)
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(statusData), // Transformation de l'objet statusData en JSON
    })

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to update ${entityName} with ID ${id}`
      )
    }

    return await response.json() // Retourne l'entité mise à jour
  } catch (error) {
    console.error(`Error updating ${entityName} with ID ${id}:`, error)
    throw error
  }
}

/**
 * Search for entities using query parameters (GET)
 * @param entityName - The name of the entity to search (e.g., 'users')
 * @param searchParams - Object representing the search parameters (e.g., { name: 'John', age: 30 })
 * @returns {Promise<any>} - Returns a promise with the search results
 */
export async function searchData(
  entityName: string,
  searchParams?: Record<string, any>
): Promise<any> {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

  const API_URL = constructUrl(getApiUrl(entityName), searchParams) // Construit l'URL avec les paramètres de recherche

  try {
    const response = await fetch(API_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to search ${entityName}`
      )
    }

    return await response.json() // Retourne les résultats de la recherche
  } catch (error) {
    console.error(`Error searching ${entityName}:`, error)
    throw error
  }
}

/**
 * Create a new entity (POST)
 * @param entityName - The name of the entity (e.g., 'users')
 * @param data - The data of the entity to be created
 * @returns {Promise<any>} - Returns a promise with the created entity's data
 */
export async function createData(
  entityName: string,
  data: Record<string, any>
): Promise<any> {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

  const API_URL = getApiUrl(entityName)

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data), // Send entity data in the request body
    })

    return await response.json() // Return the created entity
  } catch (error) {
    console.error(`Error creating ${entityName}:`, error)
    throw error
  }
}

/**
 * Update an existing entity (PUT)
 * @param entityName - The name of the entity (e.g., 'users')
 * @param id - The ID of the entity to be updated
 * @param data - The new data for the entity
 * @returns {Promise<any>} - Returns a promise with the updated entity's data
 */
export async function updateData(
  entityName: string,
  id: string,
  data: Record<string, any>
): Promise<any> {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

  const API_URL = getApiUrl(entityName)

  try {
    const response = await fetch(`${API_URL}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data), // Send updated data in the request body
    })

    return await response.json() // Return the updated entity
  } catch (error) {
    console.error(`Error updating ${entityName} with ID ${id}:`, error)
    throw error
  }
}

/**
 * Delete an entity (DELETE)
 * @param entityName - The name of the entity (e.g., 'users')
 * @param id - The ID of the entity to be deleted
 * @returns {Promise<any>} - Returns a promise confirming the deletion
 */
export async function deleteData(entityName: string, id: string): Promise<any> {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

  const API_URL = getApiUrl(entityName)

  try {
    const response = await fetch(`${API_URL}/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return await response.json() // Return confirmation of deletion
  } catch (error) {
    console.error(`Error deleting ${entityName} with ID ${id}:`, error)
    throw error
  }
}

/**
 * Remove data from a given URL (DELETE)
 * @param url - The URL to send the DELETE request to
 * @returns {Promise<any>} - Returns a promise confirming the deletion
 */
export async function removeData(entityName: string, url: string): Promise<any> {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

    const API_URL = getApiUrl(entityName)

  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (!response.ok) {
      throw new Error(`Error ${response.status}: Failed to remove data`)
    }

    return await response.json() // Return confirmation of deletion
  } catch (error) {
    console.error(`Error removing data from ${url}:`, error)
    throw error
  }
}

/**
 *
 * @param entityName
 * @param order
 * @param data
 */

export const uploadModelFile = async (
  entityName: string,
  id: string,
  fileName: string,
  datas: File[]
) => {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

  const API_URL = getApiUrl('upload/file')

  try {
    const formData = new FormData()

    datas.forEach((file) => {
      formData.append('files', file)
    })

    formData.append('relatedTo', entityName)
    formData.append('fileName', fileName)
    formData.append('relatedId', id)

    const response = await fetch(`${API_URL}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })

    return await response.json()
  } catch (error) {
    console.error(`Error uploading file :`, error)
    throw error
  }
}

export const deleteModelFile = async (
  entityName: string,
  id: string,
  fileName: string,
  fileUrl: string
) => {
  const token = getToken()
  if (!token) throw new Error('Authentication token not available')

  const API_URL = getApiUrl('delete/model/file')

  try {
    const newData = {
      relatedTo: entityName,
      fileName: fileName,
      relatedId: id,
      fileUrl: fileUrl,
    }

    const response = await fetch(`${API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newData),
    })

    return await response.json()
  } catch (error) {
    console.error(`Error uploading file :`, error)
    throw error
  }
}
