import React from 'react';
import { Pagination } from 'react-bootstrap';

interface NewPaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const NewPagination: React.FC<NewPaginationProps> = ({ currentPage, totalPages, onPageChange }) => {

    const getPageNumbers = () => {
        const pages: (number | string)[] = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (startPage > 1) {
            pages.unshift(1);
            if (startPage > 2) {
                pages.splice(1, 0, '...');
            }
        }

        if (endPage < totalPages) {
            pages.push(totalPages);
            if (endPage < totalPages - 1) {
                pages.splice(pages.length - 1, 0, '...');
            }
        }

        return pages;
    };

    return (
        <Pagination className='py-3'>
            <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
            {getPageNumbers().map((page, index) => (
                <Pagination.Item
                    key={index}
                    active={page === currentPage}
                    onClick={() => typeof page === 'number' && onPageChange(page)}
                    disabled={typeof page !== 'number'}
                >
                    {page}
                </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
    );
};

export default NewPagination;