import React, { useEffect } from 'react'
import './EventCategories.css'
import { IEventParticipantCategory, IEvent } from '../../models/IEvent'
import { createData, getData, removeData, updateData } from '../../api/request-service'
import ButtonWithModal from '../ButtonWithModal/ButtonWithModal'

interface EventCategoriesProps {
  event: IEvent
}

const EventCategories: React.FC<EventCategoriesProps> = ({ event }) => {
  const [categories, setCategories] = React.useState<
    IEventParticipantCategory[]
  >(event?.categories || [])
  const [newCategory, setNewCategory] = React.useState<string>('')
  const [editCategory, setEditCategory] =
    React.useState<IEventParticipantCategory | null>(null)

  useEffect(() => {
    setCategories(event?.categories || [])
    const runLocalData = async () => {
      const result = await getData(`events/${event.id}/participant-categories`)
      if (result.success) {
        setCategories(result.data)
      }
    }
    runLocalData()
  }, [event])

  const addCategory = async () => {
    if (newCategory.trim()) {
      const result = await createData(
        `events/${event.id}/participant-categories`,
        {
          name: newCategory.trim(),
          eventId: event.id,
        }
      )
      if (result.success) {
        const updatedCategories = [...categories, result.data]
        setCategories(updatedCategories)
      }
      setNewCategory('')
    }
  }

  const removeCategory = async (index: number) => {
    const result = await removeData(
      'events',
      `${event.id}/participant-categories/${categories[index].id}`
    )
    if (result?.success) {
      const updatedCategories = categories.filter((_, i) => i !== index)
      setCategories(updatedCategories)
    }
  }

  const handleUpdateCategory = async () => {
    const result = await updateData(
        'events',
      `${event.id}/participant-categories/${editCategory?.id}`,
      {
        name: editCategory?.name,
        eventId: event.id,
      }
    )
    if (result.success) {
      const updatedCategories = categories.map((category) => {
        if (category.id === result.data.id) {
          return result.data
        }
        return category
      })
      setCategories(updatedCategories)
      setEditCategory(null)
    }
  }

  return (
    <div className="EventCategories container">
      <h1 className="my-4">{event?.name}</h1>
      <p>{event?.description}</p>
      <h2 className="my-4">Event Participants Categories</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories?.map((category, index) => (
            <tr key={index}>
              {editCategory?.id === category.id ? (
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={editCategory.name}
                    onChange={(e) =>
                      setEditCategory({ ...editCategory, name: e.target.value })
                    }
                  />
                </td>
              ) : (
                <td>
                    {category.name}  ({category?.participants?.length || 0})
                    {category?.participants?.length ? (
                    <ButtonWithModal
                        buttonText={
                        <button className="btn btn-primary me-2">
                            <i className="fas fa-users"></i>
                        </button>
                       }
                        Component={() => (
                            <ul>
                                {category.participants.map((participant) => (
                                    <li key={participant?.company?.id}>
                                        {participant?.company?.name} 
                                    </li>
                                ))}
                            </ul>
                        )}
                    />
                ) : null}
                 </td>
              )}
              <td>
                
                {editCategory?.id === category.id ? (
                  <button
                    className="btn btn-success me-2"
                    onClick={handleUpdateCategory}
                  >
                    <i className="fas fa-check"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-warning me-2"
                    onClick={() => setEditCategory(category)}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                )}
                <button
                  className="btn btn-danger"
                  onClick={() => removeCategory(index)}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="input-group my-3">
        <input
          type="text"
          className="form-control"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="Add new category"
        />
        <button className="btn btn-primary" onClick={addCategory}>
          Add Category
        </button>
      </div>
    </div>
  )
}

export default EventCategories
