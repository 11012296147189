import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import './ModalComponent.css' // Importer le CSS pour la redimension

interface ModalComponentProps {
  show: boolean
  handleClose: () => void
  Component: React.ComponentType
  size?: "sm" | "lg" | "xl" | undefined
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  show,
  handleClose,
  Component,
  size,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size={size} // Taille optionnelle (petite, large, extra-large)
      dialogClassName="resizable-modal" // Classe pour redimensionner
      centered // Prop pour centrer la modal
    >
      <Modal.Body>
        <div className="modal-content-wrapper">
          <Component />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalComponent
