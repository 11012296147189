import React, { FC, useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import './UserSelector.css' // Assurez-vous que ce fichier existe
import AvatarCard from '../AvatarCard/AvatarCard'
import { IUser } from '../../models/IUser'
import { ICompany } from '../../models/ICompany'
import { createData, getData } from '../../api/request-service'
import { toast } from 'react-toastify'

interface SearhUserInputProps {
  onSelectionChange: (selectedIds: string[]) => void
  handleAdd: () => void
  excludedIds?: string[]
  company?: ICompany
  addInvitation?: (invitation: any) => void
}

const SearhUserInput: FC<SearhUserInputProps> = ({
  onSelectionChange,
  handleAdd,
  excludedIds = [],
  company,
  addInvitation
}) => {
  const [users, setUsers] = useState<IUser[]>([])
  const [filteredResults, setFilteredResults] = useState<(IUser | ICompany)[]>(
    []
  )
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const searchInputRef = React.createRef<HTMLInputElement>()

  // Récupérer les utilisateurs et les entreprises
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // Fetch les utilisateurs et les entreprises en parallèle
        const usersResponse = await getData('search/users?query='+searchQuery)

        setUsers(usersResponse.data)
      } catch (error) {
        console.error('Failed to fetch data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Filtrer les résultats en fonction de la chaîne de recherche
  useEffect(() => {
    if (searchQuery) {
      const query = searchQuery.toLowerCase()

      const filteredUsers = users
        .filter(
          (user) =>
            !selectedIds.includes(user.id) && !excludedIds.includes(user.id)
        )
        .filter(
          (user) =>
            `${user?.profile?.givenName} ${user?.profile?.familyName}`
              .toLowerCase()
              .includes(query) || user.email.toLowerCase().includes(query)
        )

      setFilteredResults([...filteredUsers]) // Fusionner les résultats
    } else {
      setFilteredResults([]) // Réinitialiser les résultats filtrés si la chaîne de recherche est vide
    }
  }, [searchQuery])

  // Ajouter un élément (utilisateur ou entreprise) à la sélection
  const handleSelectItem = (item: IUser | ICompany) => {
    setSelectedIds((prevIds) => {
      const newIds = [...prevIds, item.id]
      onSelectionChange(newIds)
      return newIds
    })
    setSearchQuery('') // Réinitialiser la barre de recherche après sélection
  }

  // Retirer un élément (utilisateur ou entreprise) de la sélection
  const handleRemoveItem = (itemId: string) => {
    setSelectedIds((prevIds) => {
      const newIds = prevIds.filter((id) => id !== itemId)
      onSelectionChange(newIds)
      return newIds
    })
  }

  const isEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }
  const handleAddData = async (event: any) => {
    event.preventDefault()
    console.log(selectedIds)
    if(selectedIds?.length === 0) {
      const inputValue = searchInputRef?.current?.value
      console.log('inputValue', inputValue)
      if(inputValue && isEmail(inputValue) && company){
        console.log('email', inputValue)
        const invitation = {
          firstName: '',
          lastName: '',
          email: inputValue,
          phone: '',
          companyId: company.id
        }
        // Send invitation
        try {
          await createData(`invitations`, invitation)
          if(addInvitation){
            addInvitation(invitation)
          }
          if (searchInputRef.current) {
            searchInputRef.current.value = ''
          }
          setSearchQuery('')
          toast.info(`Invitation has been sent to the user to join the company`)
        } catch (error: any) {
          console.error('Failed to send invitation:', error)
          toast.error('Failed to send invitation')
        }

      }else{
        toast.error('Please enter user email')
      }
        
    
    }else{
      handleAdd()
    }
  } 

  return (
    <div className="SearhUserInput">
      <div className="search-box mb-4 d-flex gap-1">
        <div className="flex-1">
          <input
            type="search"
            ref={searchInputRef}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search user or company"
            className="border w-100 px-4 py-2 w-full form-control"
          />
        </div>
        <button className="btn btn-success" onClick={handleAddData}>
          <i className="fa fa-plus mx-2"></i>
          Add
        </button>
      </div>

      {loading && <div>Loading users...</div>}

      {filteredResults.length > 0 && (
        <div className="autocomplete-results">
          <ul className="list-none p-0">
            {filteredResults.map((item: IUser | ICompany) => (
              <li
                key={item.id}
                className="cursor-pointer p-2 hover:bg-gray-100"
                onClick={() => handleSelectItem(item)}
              >
                {'email' in item ? <AvatarCard user={item} /> : `${item.name}`}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedIds.length > 0 && (
        <div className="selected-items mt-4">
          {[...users]
            .filter((item) => selectedIds.includes(item.id))
            .map((item) => (
              <div
                key={item.id}
                className="badge bg-blue-200 text-blue-800  px-2 py-1 flex items-center mb-2"
              >
                <AvatarCard user={item} />
                <FaTimes
                  className="ml-2 cursor-pointer"
                  onClick={() => handleRemoveItem(item.id)}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default SearhUserInput
