import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import VerifyCodeForm from '../VerifyCodeForm/VerifyCodeForm'
import SignIn from '../SignIn/SignIn'
import PrivateRoute from '../../guard/PrivateRoute'
import AdminPanel from '../AdminPanel/AdminPanel'
import Error404 from '../Error404/Error404'
import { getSubdomain } from '../../lib/utiles'
import Home from '../../pages/Home'
import ManagerPanel from '../ManagerPanel/ManagerPanel'
const TenantRouter: React.FC = () => {
  // Fonction pour obtenir le sous-domaine

  // Déterminer le tenant à partir du sous-domaine
  const tenant = getSubdomain()

  return (
    <BrowserRouter>
      <Routes>
        {tenant === 'admin' && (
          <>
            {/* Routes pour l'admin */}
            <Route path="/signin" element={<SignIn />} />
            <Route
              path="/signin/verification-code-form"
              element={<VerifyCodeForm />}
            />
            <Route
              path="/admin/:model"
              element={
                <PrivateRoute>
                  <AdminPanel />
                  {/* <NewAdminPage/> */}
                </PrivateRoute>
              }
            />
            {/* Redirection du "/" vers le panneau admin */}
            <Route path="/" element={<Home />} />
            {/* not-found route  */}
            <Route path="/not-found" element={<Error404 />} />
          </>
        )}

        {tenant === 'manager' && (
          <>
            {/* Routes pour le manager */}
            <Route path="/signin" element={<SignIn />} />
            <Route
              path="/signin/verification-code-form"
              element={<VerifyCodeForm />}
            />
            <Route
              path="/manager/:model"
              element={
                <PrivateRoute>
                  <ManagerPanel />
                </PrivateRoute>
              }
            />
            {/* Redirection du "/" vers le panneau manager */}
            <Route path="/" element={<Home />} />
          </>
        )}

        {/* Route fallback si le sous-domaine n'est pas reconnu */}
        <Route path="/not-found" element={<Error404 />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  )
}

export default TenantRouter
