import React from 'react'
import './SearchModelData.css'
import { useNavigate, useLocation } from 'react-router-dom'

interface SearchModelDataProps {
  model?: string
}

const SearchModelData: React.FC<SearchModelDataProps> = ({model}) => {
const navigate = useNavigate()
const location = useLocation()

const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTag = event.target.value
    const searchParams = new URLSearchParams(location.search)
    if (model) {
        searchParams.set('model', model)
    }
    searchParams.set('searchTag', searchTag)
    navigate(`${location.pathname}?${searchParams.toString()}`)
}

return (
    <div className="SearchModelData">
        <form className="form-inline d-flex gap-2">
            <div className="form-group mb-2 w-100">
                <input
                    type="text"
                    className="form-control"
                    id="modelSearch"
                    name='searchTag'
                    defaultValue={new URLSearchParams(location.search).get('searchTag') || ''}
                    placeholder={`Search ${model || ''}...`}
                    onChange={handleInputChange}
                />
            </div>
        </form>
    </div>
)
}

export default SearchModelData
