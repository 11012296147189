import React, { FC, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import './SideBar.css'
import { getDataById } from '../../api/request-service'
import ButtonWithModal from '../ButtonWithModal/ButtonWithModal'
import ViewProfile from '../ViewProfile/ViewProfile'
import { getMainColor } from '../../lib/utiles'
import { clearUser } from '../../helpers/utiles'

interface MenuItem {
  label: string
  path: string
  icon: string // Use an icon identifier
}

interface SideBarProps {
  menuItems: MenuItem[]
}

const SideBar: FC<SideBarProps> = ({ menuItems }) => {
  const navigate = useNavigate()
  const location = useLocation() // Hook to get the current location
  const currentPath = location.pathname // Get the current pathname

  const [user, setUser] = useState<any>(null)

  useEffect(() => {
    const runLocalData = async () => {
      try {
        const storedUser = JSON.parse(
          window.localStorage.getItem('user') || '{}'
        )

        if (storedUser && storedUser.id) {
          const newUser = await getDataById('users', storedUser.id)
          console.log({ newUser })

          setUser(newUser)
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
      }
    }

    runLocalData()
  }, [])

  console.log('Current path:', currentPath)

  const handleLogout = () => {
    clearUser() // Clear user data

    // Redirect to sign-in page
    navigate('/signin')
  }

  const mainColor = getMainColor()

  return (
    <div
      className={`d-flex flex-column flex-shrink-0 p-3 text-white ${mainColor} shadow`}
      style={{ width: 280 }}
    >
      <Link
        to="/"
        className="d-flex align-items-center gap-2 mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
      >
        <img
          src="/faacer.png"
          alt="Faacer logo"
          width={30}
          className="rounded-3"
        />
        <span className={`fs-4 text-white ${mainColor}`}>Faacer</span>
      </Link>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        {menuItems.map((item, index) => (
          <li key={index} className="nav-item">
            {/* Add "active" class if the current path matches the item's path */}
            <Link
              to={item.path}
              className={`nav-link link-light ${
                currentPath.startsWith(item.path) ? 'active' : ''
              }`}
            >
              <i className={`${item.icon} mx-2`}></i>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
      <hr />
      <div className="dropdown">
        <a
          href="#"
          className={`d-flex align-items-center text-decoration-none dropdown-toggle link-light`}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src={user?.profile?.picture || 'http://placehold.it/32'}
            width={32}
            height={32}
            className="rounded-circle me-2"
            alt="User avatar"
          />
          <strong>
            {user?.profile?.givenName} {user?.profile?.familyName} ({user?.role}
            )
          </strong>
        </a>
        <ul className="dropdown-menu text-small shadow">
          <li>
            <ButtonWithModal
              buttonText={
                <a className="dropdown-item" href="#">
                  Profile
                </a>
              }
              Component={() => <ViewProfile user={user!} />}
            />
          </li>
          <li>
            <a className="dropdown-item" href="#" onClick={handleLogout}>
              Sign out
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideBar
